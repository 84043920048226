<template>
  <component :is="tag"
             @click.native="hideSidebar"
             class="nav-item"
             v-bind="$attrs"
             tag="li">
    <a v-if="fnc" class="nav-link" @click="clickFnc()">
      <slot>
        <i v-if="icon" :class="icon"></i>
        <p>{{name}}</p>
      </slot>
    </a>
    <a v-if="!fnc" class="nav-link">
      <slot>
        <i v-if="icon" :class="icon"></i>
        <p>{{name}}</p>
      </slot>
    </a>
  </component>
</template>
<script>
export default {
  name: 'sidebar-link',
  inheritAttrs: false,
  inject: {
    autoClose: {
      default: true,
    },
    addLink: {
      default: ()=>{},
    },
    removeLink: {
      default: ()=>{},
    },
  },
  props: {
    name: String,
    icon: String,
    tag: {
      type: String,
      default: 'router-link',
    },
    fnc: {
      type: Boolean,
      default: false
    },
    fnc_name: String
  },
  data() {
    return {
      envPath: process.env.VUE_APP_ROOT_SITE,
      doacao: process.env.VUE_APP_TYPE === "doacao"
    };
  },
  methods: {
    hideSidebar() {
      if (this.autoClose) {
        this.$sidebar.displaySidebar(false);
      }
    },
    isActive() {
      if ( !this.fnc ) {
        return this.$el.classList.contains('active');
      }
      return false;
    },
    clickFnc() {
      if ( this.fnc_name == "fnc-ccbs-disponveis" ) {
        if (this.doacao) {
          window.top.location.href = this.envPath + "/campanhas"
        }
        else {
          window.top.location.href = this.envPath + "/plataforma"; //Customizado para finapop
        }
        
      }
      else if ( this.fnc_name == "fnc-voltar-site" ) {
        window.top.location.href = this.envPath;
      }
      else if ( this.fnc_name == "fnc-sair" ) {
        this.$store.commit('SET_OFERTA_ID_TEMP', '');
        this.$store.dispatch('logout');
        this.$router.push('/login');
      }
    },
  },
  mounted() {
    if (this.addLink && !this.fnc) {
      this.addLink(this);
    }
  },
  beforeDestroy() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    if (this.removeLink && !this.fnc) {
      this.removeLink(this);
    }
  },
};
</script>
<style>
</style>
