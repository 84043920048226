<script>
import settingsService from '@/services/Settings/settingsService';

import { DoubleBounce } from 'vue-loading-spinner';

export default {
  data() {
    return {
      privateSettings: {
        integracao: {
            clicksign: {
                api_keys: {}
            }
        }
      },
      publicSettings: {},
      list: [],
      Deselect: {
        render: (createElement) => createElement('span', '❌'),
      },
      settingsLoaded: false,
      isLoading: true
    };
  },
  mounted() {
    this.get();
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    }
  },
  components: {
    DoubleBounce
  },
  methods: {
    async get() {
        this.publicSettings = await settingsService.getPublicSettings();
        this.privateSettings = await settingsService.getPrivateSettings();

        this.settingsLoaded = true;
        this.isLoading = false;
    },

    async save() {
      this.isLoading = true;

      try {
        
        await settingsService.updatePublicSettings(this.publicSettings);
        await settingsService.updatePrivateSettings(this.privateSettings);

        this.$router.push('/settings#integracao');
        this.isLoading = false;
        this.privateSettings = {};
        this.publicSettings = {};

        this.$notify({
            title: 'Integração realizada com sucesso!',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 3000,
        });
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.$notify({
          title: 'Erro ao salvar integração, tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },

    checkSandboxAtivo() {
      this.$refs.integracao_clicksign_sandbox_active.click();
    },

    checkHabilitarIntegracao() {
      this.$refs.integracao_clicksign_habilitar.click();
    },
  },
};
</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="save()">
        <h5 slot="header" class="title">
          <router-link to="/settings#integracao" class="fa fa-arrow-left"></router-link>
          Voltar
        </h5>

        <div v-if="isLoading" class="loading-spinner">
            <double-bounce></double-bounce>
        </div>

        <div class="row" v-if="settingsLoaded && !isLoading">
            <div class="col-12">
                <div class="row">
                    <div class="col-md-3 mt-3">
                        <input
                            name="integracao_clicksign_habilitar"
                            ref="integracao_clicksign_habilitar"
                            type="checkbox"
                            v-model="privateSettings.integracao.clicksign.enabled"
                        />
                        <label
                            for="integracao_clicksign_habilitar"
                            style="margin-left: 5px;"
                            @click="checkHabilitarIntegracao"
                        >
                            HABILITAR INTEGRAÇÃO
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-md-3 mt-3">
                        <base-input
                        label="Token PRD"
                        placeholder="Informe o token produtivo"
                        v-model="privateSettings.integracao.clicksign.api_keys.access_token_prd"
                        >
                        </base-input>
                    </div>
                    <div class="col-md-3 mt-3">
                        <base-input
                        label="Token Sandbox"
                        placeholder="Informe o token de sandbox"
                        v-model="privateSettings.integracao.clicksign.api_keys.access_token_sandbox"
                        >
                        </base-input>
                    </div>
                    <div class="col-md-3 mt-3">
                        <input
                            name="integracao_clicksign_sandbox_active"
                            ref="integracao_clicksign_sandbox_active"
                            type="checkbox"
                            v-model="privateSettings.integracao.clicksign.api_keys.access_token_sandbox_active"
                        />
                        <label
                            for="integracao_clicksign_sandbox_active"
                            style="margin-left: 5px; margin-top: 36px"
                            @click="checkSandboxAtivo"
                        >
                            Sandbox Ativo
                        </label>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-3 mt-3">
                        <base-input
                        label="Prefixo Pasta Raíz"
                        placeholder="Informe o prefixo"
                        v-model="privateSettings.integracao.clicksign.path_prefixo"
                        >
                        </base-input>
                    </div>
                    <div class="col-md-3 mt-3">
                      <base-input
                        label="Dias de vigência (pós encerramento oferta)"
                        placeholder="Informe a quantidade de dias"
                        v-model="privateSettings.integracao.clicksign.due_date_assign"
                      >
                      </base-input>
                    </div>
                </div>

                <button type="submit" class="btn btn-sm">Salvar</button>
            </div>
        </div>
      </form>
    </card>
  </div>
</template>
