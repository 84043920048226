<template>
  <card>
    <h5 slot="header" class="title">Informe de Rendimentos</h5>
    <div class="row">
      <div class="col-md-4">
        <base-input required label="Informe o ano fiscal *" :boldLabel="true">
          <v-select class="style-chooser" v-model="fiscal_year" :options="options" name="fiscal_year"></v-select>
        </base-input>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <button class="btn btn-sm" style="font-size: 12px; padding: 10px 36px;"
          @click="downloadReport()">Download teste</button>
      </div>
    </div>

    <report-layout id="myelement" :fiscal_year="fiscal_year" :logo="logo" :report_data="report_data"
      :platformName="platformName" :investidor="investidor"></report-layout>
  </card>
</template>

<script>
import financingService from '@/services/Financing/financingService'
import ordersService from '@/services/Orders/ordersService'
import settingsService from '@/services/Settings/settingsService'
import { Printd } from 'printd'
import Vue from 'vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import ReportLayout from './ReportLayout.vue'
Vue.component('v-select', vSelect)

export default {
  components: {
    ReportLayout,
  },
  data() {
    return {
      fiscal_year: 2023,
      logo: require(process.env.VUE_APP_LOGO_URL),
      options: [],
      report_data: [],
      investidor: this.$store.getters.getPessoa,
      platformName: '',
      taxPix: 0,
    }
  },
  mounted() {
    this.setOptions()
    this.getSettings()
  },
  methods: {
    setOptions() {
      const currentYearMinusOne = new Date().getFullYear() - 1
      const baseYear = 2023

      for (let year = baseYear; year <= currentYearMinusOne; year++) {
        this.options.push(year)
      }
    },
    async getSettings() {
      const settingsData = await settingsService.getPrivateSettings() || {}
      this.platformName = settingsData.platformName || ''
      this.taxPix = Number(settingsData.integracao.safe2pay.pix.tax) || 0
    },
    async downloadReport() {
      try {
        const financingResponse = await financingService.getFinancingByYear(this.fiscal_year)
        const financing = financingResponse.docs
        const userId = this.$store.getters.getPessoa.pessoa_id

        const ordersResponse = await ordersService.getOrdersByUser(userId)

        const reports = []

        ordersResponse.forEach((order) => {
          try {
            if (order.status !== "Pago") {
              return
            }

            const finance = financing.find((finance) => finance.id === order.financing_id)

            if (finance === undefined) {
              return
            }

            const financeData = finance.data()
            const empresa = financeData.dados_complementares.empresa
            const cnpj = empresa.documento.numero
            const nome = empresa.nome
            const valorBruto = order.ccb.simulacao.payload.ValorBruto || Number(order.item.value) - this.taxPix
            // const valorBruto = Math.floor(Math.random() * (999999 - 100 + 1)) + 100

            let report = reports.find((report) => report.cnpj === cnpj)

            if (report === undefined) {
              reports.push({
                cnpj,
                nome,
                valorBruto
              })

              return
            }

            report.valorBruto += valorBruto
          } catch (error) {
            console.error('Error processing order:', error)
            this.$notify({
              title: 'Ooppss!',
              message: error.message,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'warning',
              timeout: 3000,
            })
          }
        })

        this.report_data = reports

        this.$nextTick(() => {
          if (this.report_data.length > 0) {
            const options = {
              headElements: [],
            }

            const d = new Printd(options)
            d.print(document.getElementById('myelement'))
          } else {
            this.$notify({
              title: 'Ooppss!',
              message: 'Investidor sem operações para o período informado',
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'warning',
              timeout: 3000,
            })
          }
        })
      } catch (error) {
        console.error('Error downloading report:', error)
        this.$notify({
          title: 'Ooppss!',
          message: `Não foi possível gerar o informe de rendimentos entre em contato através do email: vmxengenharia@gmail.com`,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        })
      }
    },
  },
}
</script>

<style scoped></style>
