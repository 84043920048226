import Axios from 'axios';

export default async pessoaId => {
  const endpoint = process.env.VUE_APP_ENDPOINT_WS + 'processInvestimentsPerPerson';
  try {
    return await Axios.post(endpoint, { pessoaId }).then(r => r.data);
  } catch (error) {
    throw new Error(error.message);
  }
};
