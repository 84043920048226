<script>
import bancarizadoresService from '@/services/Bancarizadores/bancarizadoresService';
import attachmentsService from '@/services/Attachments/attachmentsService';
import pessoasService from '@/services/Pessoas/pessoasService';
import logsService from '@/services/Logs/logsService';
import financingService from '@/services/Financing/financingService';

import { collectionRef } from '@/firebase';

import {vueTopprogress} from 'vue-top-progress';
import VueFileAgent from 'vue-file-agent';
import Vue from 'vue';

import { DoubleBounce } from 'vue-loading-spinner';

import {
  EnderecoPadrao,
  BancarizadorIntegracaoID
} from '@/store/cwl_enums';

Vue.use(VueFileAgent);

export default {
  data() {
    return {
      object: {},
      cachedObject: {},
      isLoading: true,

      // Busca endereço pelo cep
      cepForSearch: '',
      skipCepSearch: true,
      isLoadingEndereco: false,
      endereco: {},
      showAddressFields: false,

      Deselect: {
        render: (createElement) => createElement('span', '❌'),
      },
      tipos_bancarizadores: ['Integrado', 'Manual'],
      tipos_integracoes_bancarizadores: ['SOCINAL', 'FIDÚCIA'],
      list_sim_nao: [
        {
          value: false,
          label: "NÃO"
        },
        {
          value: true,
          label: "SIM"
        }
      ],

      // Imagem
      fileRecords: [],

      bancos: [],

      dados_bancarios: {
        banco: {},
        agencia_numero: '',
        agencia_digito: '',
        conta_numero: '',
        conta_digito: '',
      },

      responsaveis: [],
    };
  },
  components: {
    vueTopprogress,
    DoubleBounce
  },
  computed: {
    objectId() {
      return this.$route.params.id;
    },
    currentPessoa() {
      return this.$store.getters.getPessoa;
    }
  },
  mounted() {
    this.get();
    this.getBancos();
  },
  methods: {
    async get() {
      try {
        await bancarizadoresService.get(this.objectId).then(function(object) {
          this.object = object;
          this.object.id = this.objectId;
          this.cachedObject = object;
          this.cachedObject.id = this.objectId;

          this.endereco.rua = this.object.endereco_rua;
          this.endereco.numero = this.object.endereco_numero;
          this.endereco.complemento = this.object.endereco_complemento;
          this.endereco.bairro = this.object.endereco_bairro;
          this.endereco.cidade = this.object.cidade;
          this.endereco.estado = this.object.uf;
          this.endereco.cep = this.object.cep;

          if ( object.dados_bancarios ) {
            this.dados_bancarios = object.dados_bancarios;
          }

          if ( this.endereco.cep.length > 0 ) {
            this.cepForSearch = this.endereco.cep;
            this.showAddressFields = true;
          }

          if ( this.object.logotipoURL ) {
            const objImg = {
              name: this.object.id,
              url: this.object.logotipoURL,
              type: 'image',
              size: 333,
            };
            this.fileRecords.push(objImg);
          }

          this.responsaveis = this.object.responsaveis || [];
          if ( this.responsaveis.length == 0 && this.object.responsavel && this.object.responsavel.id ) {
            this.responsaveis = [this.object.responsavel];
          }

          if ( this.responsaveis.length == 0 ) {
            this.addResponsavel();
          }

          this.isLoading = false;
        }.bind(this));
      } catch (error) {
        console.log(error);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },

    addResponsavel() {
      var pessoaToAdd = this.$CWLNewPessoaPadrao();
      pessoaToAdd.key = this.responsaveis.length + 1;
      this.responsaveis.push(pessoaToAdd);
    },

    deleteResponsavel() {
        this.responsaveis.splice((this.responsaveis.length - 1), 1);
    },

    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    },

    async salvarResponsaveis() {
      let responsaveisSwap = [];
      await this.asyncForEach(this.responsaveis, async (responsavel) => {
          if ( !responsavel.id ) {
              let pessoaCriada = await pessoasService.criarPessoaSemValidacao(responsavel);
              responsaveisSwap.push(pessoaCriada);
          }
          else {
              await pessoasService.updatePessoa(responsavel.id, responsavel);
              responsaveisSwap.push(responsavel);
          }
      });
      this.responsaveis = responsaveisSwap;
    },

    async getBancos() {
      const that = this;

      await collectionRef('banks')
        .orderBy('name', 'asc')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((banco) => {
            const obj = banco.data();
            obj.id = banco.id;
            that.bancos.push(obj);
          });
        })
        .catch((error) => console.error('error', error));
    },

    async update() {
      this.isLoading = true;

      this.object.endereco = this.endereco.rua;
      this.object.endereco_rua = this.endereco.rua;
      this.object.endereco_numero = this.endereco.numero;
      this.object.endereco_complemento = this.endereco.complemento;
      this.object.endereco_bairro = this.endereco.bairro;
      this.object.cidade = this.endereco.cidade;
      this.object.uf = this.endereco.estado;
      this.object.cep = this.cepForSearch;
      if ( this.endereco.numero.length > 0 ) {
        this.object.endereco = this.object.endereco + ", " + this.endereco.numero;
      }
      if ( this.endereco.complemento.length > 0 ) {
        this.object.endereco = this.object.endereco + ", " + this.endereco.complemento;
      }

      if ( this.object.tipo == "Manual" ) {
        this.object.integracao_id = BancarizadorIntegracaoID.Manual;
      }

      this.object.dados_bancarios = this.dados_bancarios;

      try {
        const logUser = {
            user: {
                email: this.currentPessoa.email.email
            }
        };
        logsService.createNewLog(logUser, '', 'Atualizar Bancarizador', '', this.object);
        if ( this.object.padrao.value ) {
          await bancarizadoresService.removerPadraoDeTodos();
        }
        await bancarizadoresService.update(this.objectId, this.object);

        const that = this;
        if (this.fileRecords.length > 0 && this.fileRecords[0].file) {
          const file = this.fileRecords[0];
          await attachmentsService.addNewFinancingImageAttachment(
            file.file,
            file.file.name,
            'Bancarizadores',
            this.objectId,
          )
          .then(async function (attachmentCreated) {
            const bucket = 'Bancarizadores/' + that.objectId;
            that.object.logotipoURL = await attachmentsService.urlAttachment(
              attachmentCreated.ref.name,
              bucket,
            );
            await bancarizadoresService.update(that.objectId, that.object);

            await that.salvarResponsaveis();
            that.object.responsaveis = that.responsaveis;

            await bancarizadoresService.update(that.objectId, that.object);

            const financings = await financingService.getAllByBancarizadorId(that.objectId);
            financings.forEach(async function(financingFromFirebase) {
              var financing = financingFromFirebase.data();
              financing.bancarizador = that.object;

              await financingService.update(financing.id, financing);
            });

            that.$router.push('/bancarizadores');
            
            that.$notify({
              title: 'Bancarizador atualizado com sucesso!',
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'success',
              timeout: 3000,
            });
          });
        }
        else {

          await bancarizadoresService.update(that.objectId, that.object);

          await that.salvarResponsaveis();
          console.log(that.responsaveis);
          that.object.responsaveis = that.responsaveis;

          await bancarizadoresService.update(that.objectId, that.object);

          const financings = await financingService.getAllByBancarizadorId(that.objectId);
          financings.forEach(async function(financingFromFirebase) {
            var financing = financingFromFirebase.data();
            financing.bancarizador = that.object;

            await financingService.update(financing.id, financing);
          });

          that.$router.push('/bancarizadores');

          that.$notify({
            title: 'Bancarizador atualizado com sucesso!',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 3000,
          });
        }
      } catch (error) {
        this.isLoading = false;
        console.log(error);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },

    async changeCEP(cep) {
      const cepWithoutMask = cep
        .replace('.', '')
        .replace('.', '')
        .replace('/', '')
        .replace('-', '');
      if (cepWithoutMask.length == 8 && !this.skipCepSearch) {
        if (!this.isLoadingEndereco) {
          this.isLoadingEndereco = true;
          this.endereco = Object.assign({}, EnderecoPadrao);

          const enderecoFound = await pessoasService.searchAddressByZipcode(
            cep,
          );
          if (enderecoFound) {
            this.endereco = enderecoFound;
          }

          this.object.endereco = this.endereco.rua;
          this.object.endereco_numero = this.endereco.numero;
          this.object.endereco_complemento = this.endereco.complemento;
          this.object.endereco_bairro = this.endereco.bairro;
          this.object.cidade = this.endereco.cidade;
          this.object.uf = this.endereco.estado;

          this.showAddressFields = true;
          this.isLoadingEndereco = false;
        }
      } else if (!this.isLoadingEndereco && !this.skipCepSearch) {
        this.endereco = {};
        this.showAddressFields = false;
      }
      this.skipCepSearch = false;
    },
  },
};
</script>

<template>
  <div class="add-user">
    <form @submit.prevent="update()">
      <card>
        <h5 slot="header" class="title">
          <router-link to="/bancarizadores" class="fa fa-arrow-left"></router-link>
          Editar Bancarizador
        </h5>

        <div v-if="isLoading" class="loading-spinner">
            <double-bounce></double-bounce>
        </div>

        <div v-if="!isLoading" class="row">
          <div class="col-md-3 mt-3">
            <base-input
              label="Nome*"
              placeholder="Informe o nome"
              v-model="object.name"
              required
            >
            </base-input>
          </div>
          
          <div class="col-md-3 mt-3">
            <base-input required label="Tipo*">
              <v-select
                name="tipos_bancarizadores"
                label="Tipos Bancarizadores"
                class="style-chooser"
                :options="tipos_bancarizadores"
                v-model="object.tipo"
                :components="{ Deselect }"
              ></v-select>
            </base-input>
          </div>
          <div class="col-md-3 mt-3" v-if="object.tipo == 'Integrado'">
            <base-input required label="Integração*">
              <v-select
                name="tipo_integracao_bancarizador"
                label="Tipos Integrações Bancarizadores"
                class="style-chooser"
                :options="tipos_integracoes_bancarizadores"
                v-model="object.integracao_id"
                :components="{ Deselect }"
              ></v-select>
            </base-input>
          </div>
          <div class="col-md-3 mt-3">
            <base-input required label="Bancarizador Padrão*">
              <v-select
                name="bancarizador_padrao"
                label="label"
                class="style-chooser"
                :options="list_sim_nao"
                v-model="object.padrao"
                :components="{ Deselect }"
              ></v-select>
            </base-input>
          </div>
        </div>
        <div v-if="!isLoading" class="row">
          <div class="col-md-12">
            <label>Logotipo (tamanho sugerido: 596 x 186)*</label>
            <VueFileAgent
              v-model="fileRecords"
              :multiple="false"
              :deletable="false"
              :meta="false"
              :accept="'image/*'"
              :maxSize="'5MB'"
              :helpText="'Arraste uma imagem aqui'"
              :errorText="{
                type: 'Por favor, selecione uma imagem',
                size: 'A imagem não pode ter mais de 5MB',
              }"
            ></VueFileAgent>
          </div>
        </div>
        <div v-if="!isLoading" class="row">
          <div class="col-md-3 mt-3">
            <base-input
              label="Razão Social*"
              placeholder="Informe a razão social"
              v-model="object.razao_social"
              required
            >
            </base-input>
          </div>
          <div class="col-md-3 mt-3">
            <base-input
              label="CNPJ*"
              placeholder="Informe o CNPJ"
              v-model="object.cnpj"
              v-mask="['##.###.###/####-##']"
              required
            >
            </base-input>
          </div>
          <div class="col-md-3 mt-3">
            <base-input
              label="E-mail*"
              placeholder="Informe e-mail"
              v-model="object.email"
              required
            >
            </base-input>
          </div>
          <div class="col-md-3 mt-3">
            <base-input
              label="Natureza*"
              placeholder="Informe a natureza"
              v-model="object.natureza"
              required
            >
            </base-input>
          </div>
          <div class="col-md-3 mt-3">
            <base-input
              label="Praça Pagamento*"
              placeholder="Informe a praça de pagamento"
              v-model="object.praca_pagamento"
              required
            >
            </base-input>
          </div>
          <div class="col-md-3 mt-3">
            <base-input
              label="CEP*"
              v-model="cepForSearch"
              placeholder="Informe o CEP do endereço"
              v-mask="['##.###-###']"
              v-on:input="changeCEP"
              required
            ></base-input>
            <label v-if="isLoadingEndereco" style="cursor: default"
              ><i>Pesquisando...</i></label
            >
          </div>
          <div class="col-md-3 mt-3">
            <base-input
              label="Endereço*"
              placeholder="Informe o endereço"
              v-model="endereco.rua"
              required
              v-if="this.showAddressFields"
            >
            </base-input>
          </div>
          <div class="col-md-3 mt-3">
            <base-input
              label="Número*"
              placeholder="Informe o número do endereço"
              v-model="endereco.numero"
              required
              v-if="this.showAddressFields"
            >
            </base-input>
          </div>
          <div class="col-md-3 mt-3">
            <base-input
              label="Complemento"
              placeholder="Informe o complemento do endereço"
              v-model="endereco.complemento"
              v-if="this.showAddressFields"
            >
            </base-input>
          </div>
          <div class="col-md-3 mt-3">
            <base-input
              label="Bairro"
              placeholder="Informe o bairro do endereço"
              v-model="endereco.bairro"
              v-if="this.showAddressFields"
            >
            </base-input>
          </div>
          <div class="col-md-3 mt-3">
            <base-input
              label="Cidade*"
              placeholder="Informe a cidade"
              v-model="endereco.cidade"
              required
              v-if="this.showAddressFields"
            >
            </base-input>
          </div>
          <div class="col-md-3 mt-3">
            <base-input
              label="UF*"
              placeholder="Informe o UF"
              v-model="endereco.estado"
              required
              v-if="this.showAddressFields"
            >
            </base-input>
          </div>
        </div>

      <div v-if="!isLoading" class="row">
          <div class="col-md-3 mt-3">
            <base-input required label="Banco*">
              <v-select
                name="banco_responsavel"
                label="name"
                class="style-chooser"
                :options="bancos"
                v-model="dados_bancarios.banco"
                :components="{ Deselect }"
                required
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    <em style="opacity: 0.9; color: #555"
                      ><b>{{ search }}</b> não encontrado</em
                    >
                  </template>
                </template>
              </v-select>
            </base-input>
          </div>
          <div class="col-md-3 mt-3">
            <base-input required label="Subconta Safe2Pay*">
              <v-select
                name="subconta_safe2pay"
                label="label"
                class="style-chooser"
                :options="list_sim_nao"
                v-model="object.subconta_safe2pay"
                :components="{ Deselect }"
              ></v-select>
            </base-input>
          </div>
      </div>

      <div v-if="!isLoading" class="row">
        <div class="col-md-3 mt-3">
          <base-input
            label="Agência*"
            placeholder="Informe sua agência"
            v-mask="['####']"
            v-model="dados_bancarios.agencia_numero"
            required
          ></base-input>
        </div>
        <div class="col-md-3 mt-3">
          <base-input
            label="Dígito da agência*"
            placeholder="Dígito"
            v-mask="['#']"
            v-model="dados_bancarios.agencia_digito"
            required
          ></base-input>
        </div>
        <div class="col-md-3 mt-3">
          <base-input
            label="Conta*"
            placeholder="Informe o número da sua conta"
            v-mask="['####################']"
            v-model="dados_bancarios.conta_numero"
            required
          ></base-input>
        </div>
        <div class="col-md-3 mt-3">
          <base-input
            label="Dígito da conta*"
            placeholder="Dígito"
            v-mask="['#']"
            v-model="dados_bancarios.conta_digito"
            required
          ></base-input>
        </div>
      </div>

      <!-- <div class="row">
          <div class="col-md-3 mt-3">
              <base-input
                  label="Representante do bancarizador*"
                  v-model="pessoaResponsavelPeloBancarizador.nome"
                  placeholder="Informe o nome do representante"
                  required
              ></base-input>
          </div>
          <div class="col-md-3 mt-3">
              <base-input
                  label="CPF do representante*"
                  v-model="pessoaResponsavelPeloBancarizador.documento.numero"
                  placeholder="Informe o CPF"
                  v-mask="['###.###.###-##']"
                  required
              ></base-input>
          </div>
          <div class="col-md-3 mt-3">
              <base-input
                  label="E-mail do representante*"
                  v-model="pessoaResponsavelPeloBancarizador.email.email"
                  placeholder="Informe o e-mail"
                  required
              ></base-input>
          </div>
          <div class="col-md-3 mt-3">
              <base-input
                  label="Telefone do representante*"
                  v-model="pessoaResponsavelPeloBancarizador.telefone.telefone"
                  placeholder="Informe o telefone"
                  v-mask="['(##) #####-####']"
                  required
              ></base-input>
          </div>
          <div class="col-md-3 mt-3">
              <base-input
                  label="Data de nascimento do representante*"
                  v-model="pessoaResponsavelPeloBancarizador.dados_complementares.birthday"
                  placeholder="Informe a data de nascimento"
                  v-mask="['##/##/####']"
                  required
              ></base-input>
          </div>
      </div> -->
      </card>

      <card v-if="!isLoading">
        <h5 slot="header" class="title">Dados dos Responsáveis</h5>

        <div class="row" v-for="responsavel in responsaveis" :key="responsavel.key">
          <div class="col-12">
            <div class="row">
              <div class="col-md-3 mt-3">
                <base-input
                    label="Representante do bancarizador*"
                    v-model="responsavel.nome"
                    placeholder="Informe o nome do representante"
                    required
                ></base-input>
              </div>

              <div class="col-md-3 mt-3">
                <base-input
                    label="CPF do representante*"
                    v-model="responsavel.documento.numero"
                    placeholder="Informe o CPF"
                    v-mask="['###.###.###-##']"
                    required
                ></base-input>
              </div>

              <div class="col-md-3 mt-3">
                <base-input
                    label="E-mail do representante*"
                    v-model="responsavel.email.email"
                    placeholder="Informe o e-mail"
                    required
                ></base-input>
              </div>

              <div class="col-md-3 mt-3">
                <base-input
                    label="Telefone do representante*"
                    v-model="responsavel.telefone.telefone"
                    placeholder="Informe o telefone"
                    v-mask="['(##) #####-####']"
                    required
                ></base-input>
              </div>

              <div class="col-md-3 mt-3">
                <base-input
                    label="Data de nascimento do representante*"
                    v-model="responsavel.dados_complementares.birthday"
                    placeholder="Informe a data de nascimento"
                    v-mask="['##/##/####']"
                    required
                ></base-input>
              </div>
              <div class="row col mt-3" v-if="responsavel.clicksign">
                <div class="col-md-4" v-if="responsavel.clicksign.signatario_key && responsavel.clicksign.is_valid">
                  <base-input
                    label="Integração ClickSign"
                    v-model="responsavel.clicksign.signatario_key"
                    placeholder="Integração ClickSign"
                    readonly
                  />
                </div>
                <template v-else>
                  <div class="col-md-2">
                    <base-input
                      label="Códido do Erro"
                      v-model="responsavel.clicksign.error_code"
                      placeholder="Códido do Erro"
                      readonly
                    ></base-input>
                  </div>
                  <div class="col-md-5">
                    <base-input
                      label="Mensagem de Erro"
                      v-model="responsavel.clicksign.error_message"
                      placeholder="Mensagem de Erro"
                      readonly
                    ></base-input>
                  </div>
                  <div class="col-md-4 mt-4">
                    <base-checkbox
                      v-model="responsavel.dados_complementares.force_add"
                    > 
                      Forçar criação de signatário
                    </base-checkbox>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
            <div class="col-md-12 mt-12">
                <button type="button" class="btn btn-sm" @click="addResponsavel">Adicionar Responsável</button>
                <button v-if="responsaveis.length > 1" type="button" class="btn btn-sm btn-warning" @click="deleteResponsavel">Remover Responsável</button>
            </div>
        </div>
      </card>

      <card v-if="!isLoading">
        <button
          type="submit"
          class="btn btn-sm"
        >
          Salvar
        </button>
      </card>

    </form>
  </div>
</template>
