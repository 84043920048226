import axios from 'axios';
import { PessoaCaracteristicas } from '@/store/cwl_enums';
import firebase from 'firebase';
import { currentUser } from '../../firebase';
import { withLog } from '../../logger/with_log';
import hubspotService from '@/services/HubSpot/hubspotService';

export default {
  pessoaRef() {
    return firebase.firestore().collection('pessoas');
  },
  async getAllInvestors() {
    return withLog(() => this.pessoaRef().orderBy('email.email', 'asc').get());
  },

  async getAllInvestorsUsuario() {
    return withLog(() => this.pessoaRef().where('caracteristicas', 'array-contains', 'usuario').get());
  },

  async createNewInvestor(investor, enviarEmail = true) {
    let that = this;

    return await withLog(async () => {
      const ref = this.pessoaRef();
      const uid = currentUser().uid;
      console.debug('uid', uid);
      if (!uid) {
        throw 'usuário não foi criado';
      }

      var doc = await ref.doc(uid).get();
      if (!doc.exists) {
        var data = { ...investor, id: uid };
        ref.doc(uid).set(data);

        if ( enviarEmail ) {
          that.enviarEmailNovoCadastroAnalise(data);
        }

        try {
          hubspotService.criarContatoAPI(uid);
        }
        catch (error) {
          console.log(error);
        }

        return data;
      }
    });
  },

  async getInvestor(email) {
    try {
      console.debug('get investor by email', email);
      const snapshot = await this.pessoaRef()
        .where('email.email', '==', email)
        .where('caracteristicas', 'array-contains-any', PessoaCaracteristicas)
        .get();

      return snapshot.empty
        ? undefined
        : { id: snapshot.docs[0].id, ...snapshot.docs[0].data() };
    } catch (error) {
      console.error('get investor by email error', error);
    }
  },

  async get(id) {
    return await withLog(() => this.pessoaRef().doc(id).get());
  },

  async getDetail(id) {
    return await withLog(() => this.pessoaRef().doc(id).get());
  },

  setInvestors(email) {
    withLog(() =>
      this.pessoaRef()
        .where('email.email', '==', email)
        .get()
        .then((response) => {
          response.forEach((doc) => {
            const newInvestor = {
              user: doc.data().dados_complementares.user.email,
              status: doc.data().dados_complementares.status,
            };
          });
        }),
    );
  },

  async updateTermsOfUseAcceptedToFalse() {
    withLog(() =>
      this.pessoaRef()
        .get()
        .then((response) => {
          response.forEach((doc) => {
            const investor = doc.data();
            investor.dados_complementares.termsOfUseAccepted = false;
            withLog(() => this.pessoaRef().doc(doc.id).update(investor));
          });
        }),
    );
  },

  async updateInvestor(id, investor, sendEmail = false) {
    await withLog(() => this.pessoaRef().doc(id).update(investor));
    
    if ( sendEmail ) {
      this.enviarEmailAtualizacaoCadastroAnalise(investor);
    }

    try {
      hubspotService.atualizarContatoAPI(id);
    }
    catch (error) {
      console.log(error);
    }
  },

  async enviarEmailAtualizacaoCadastroAnalise(pessoa) {
    const endpoint = process.env.VUE_APP_ENDPOINT_WS + "sendMail";
    await axios.post(endpoint, {
      email: pessoa.email.email,
      name: pessoa.nome,
      pessoa: pessoa,
      status: 'cadastro-atualizado-em-analise',
    });
  },

  async enviarEmailAtualizacaoCadastroAprovado(pessoa) {
    const endpoint = process.env.VUE_APP_ENDPOINT_WS + "sendMail";
    await axios.post(endpoint, {
      email: pessoa.email.email,
      name: pessoa.nome,
      pessoa: pessoa,
      status: 'cadastro-aprovado',
    });
  },

  async enviarEmailAtualizacaoCadastroReprovado(pessoa) {
    const endpoint = process.env.VUE_APP_ENDPOINT_WS + "sendMail";
    await axios.post(endpoint, {
      email: pessoa.email.email,
      name: pessoa.nome,
      pessoa: pessoa,
      status: 'cadastro-reprovado',
    });
  },

  async enviarEmailNovoCadastroAnalise(pessoa) {
    const endpoint = process.env.VUE_APP_ENDPOINT_WS + "sendMail";
    await axios.post(endpoint, {
      email: pessoa.email.email,
      name: pessoa.nome,
      pessoa: pessoa,
      templateType: 'boas_vindas', 
      status: 'cadastro-novo-em-analise',
    });
  },
};
