import Firebase from 'firebase/app';

import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/storage';
import firebase from 'firebase/app';

const firebaseConfigEquityDSV = {
  apiKey: "AIzaSyAuYG0_CVtLFNYq4Vr3X3Re3lDfGCRooFg",
  authDomain: "ecwl-equity-dsv.firebaseapp.com",
  projectId: "ecwl-equity-dsv",
  storageBucket: "ecwl-equity-dsv.appspot.com",
  messagingSenderId: "701470170074",
  appId: "1:701470170074:web:a375c2fd50f381e02b3ee3",
  measurementId: "G-0M8H8CL3JD"
};

const firebaseConfigP2DSV = {
  apiKey: "AIzaSyD9cKkkOP1y6yD9o1jCF1j3GEqZXEPZugQ",
  authDomain: "ecwl-p2-dsv.firebaseapp.com",
  projectId: "ecwl-p2-dsv",
  storageBucket: "ecwl-p2-dsv.appspot.com",
  messagingSenderId: "309790189484",
  appId: "1:309790189484:web:c704f78168c8b4830dbc09",
  measurementId: "G-XYYM3G5CZ5"
};

const firebaseConfigP3DSV = {
  apiKey: "AIzaSyCHmdKV3PdNMI3gZZvylBUYOuXnEzRSWKE",
  authDomain: "ecwl-p3-dsv.firebaseapp.com",
  projectId: "ecwl-p3-dsv",
  storageBucket: "ecwl-p3-dsv.appspot.com",
  messagingSenderId: "108742383144",
  appId: "1:108742383144:web:38d0c55bcb0d4d0bd8c71d",
  measurementId: "G-5E2RZVH2T3"
};

const firebaseConfigP1Demo = {
  apiKey: "AIzaSyDcLVG6NpWhr5Lw6iHV8wyO1MqFxo1u-0g",
  authDomain: "ecwl-p1-demo.firebaseapp.com",
  projectId: "ecwl-p1-demo",
  storageBucket: "ecwl-p1-demo.appspot.com",
  messagingSenderId: "253584800882",
  appId: "1:253584800882:web:02ff3f79bd792caf4056eb",
  measurementId: "G-4K3PKPGJHK"
};

const firebaseConfigP2Demo = {
  apiKey: "AIzaSyB-mcgG_cW8Obp2r8vvMRvlOPhoSABWesk",
  authDomain: "ecwl-p2-demo.firebaseapp.com",
  projectId: "ecwl-p2-demo",
  storageBucket: "ecwl-p2-demo.appspot.com",
  messagingSenderId: "647548348357",
  appId: "1:647548348357:web:6fc1dd8a19d38a4c638dac",
  measurementId: "G-ZNH4HCKVQ7"
};

const firebaseConfigP3Demo = {
  apiKey: "AIzaSyADz878lqSoGnru00HtM3BkMAy-UFoSiZI",
  authDomain: "ecwl-p3-demo.firebaseapp.com",
  projectId: "ecwl-p3-demo",
  storageBucket: "ecwl-p3-demo.appspot.com",
  messagingSenderId: "205535967882",
  appId: "1:205535967882:web:ed84aced282851476ccb73",
  measurementId: "G-TXG8REBPVS"
};

const firebaseConfigDEMO = {
  apiKey: 'AIzaSyD-Z7fN-AV-gnmIuAFvj43cECDYepRnMHU',
  authDomain: 'cwl-demo.firebaseapp.com',
  databaseURL: 'https://cwl-demo.firebaseio.com',
  projectId: 'cwl-demo',
  storageBucket: 'cwl-demo.appspot.com',
  messagingSenderId: '207854671484',
  appId: '1:207854671484:web:8cecc40d3d67477d2c1bf9',
  measurementId: 'G-MFP2HG8JR6',
};

const firebaseConfigDoacaoOficial = {
  apiKey: 'AIzaSyD7LCZUwQVLyQjMHOzvpsM1wTtPy1KLUFo',
  authDomain: 'cwl-doacao-oficial.firebaseapp.com',
  projectId: 'cwl-doacao-oficial',
  storageBucket: 'cwl-doacao-oficial.appspot.com',
  messagingSenderId: '475121842580',
  appId: '1:475121842580:web:b8007c444effd0db4a3781',
  measurementId: 'G-V0SBHHZ045',
};

const firebaseConfigICoOwner = {
  apiKey: 'AIzaSyDxBC-WTf5yJsl8elq_TxxggDD80lFrv2k',
  authDomain: 'ecwl-icoowner.firebaseapp.com',
  projectId: 'ecwl-icoowner',
  storageBucket: 'ecwl-icoowner.appspot.com',
  messagingSenderId: '618596401311',
  appId: '1:618596401311:web:f4b8c4d87bf5c8e81722aa',
};

const firebaseConfigICoOwnerStage = {
  apiKey: 'AIzaSyCPkzqRuMi9cIsW9riVwSEZgQYNg8HC8Qk',
  authDomain: 'ecwl-icoowner-stage.firebaseapp.com',
  projectId: 'ecwl-icoowner-stage',
  storageBucket: 'ecwl-icoowner-stage.appspot.com',
  messagingSenderId: '515879709351',
  appId: '1:515879709351:web:56ec6b4ff2b3d2d8cd4982',
};

const firebaseConfigDPStage = {
  apiKey: "AIzaSyADme9hzCRWAPxJMwIairlDvHTDhaYoQJs",
  authDomain: "ecwl-dp-stage.firebaseapp.com",
  projectId: "ecwl-dp-stage",
  storageBucket: "ecwl-dp-stage.appspot.com",
  messagingSenderId: "558480588078",
  appId: "1:558480588078:web:916f4fe887056c46d48ebf",
  measurementId: "G-2TSXMW3HXF"
};

const firebaseConfigFinapopStage = {
  apiKey: "AIzaSyDs89EjMna5R3lAmX4psHYpJ53jTG5gJqA",
  authDomain: "ecwl-finapop-stage.firebaseapp.com",
  projectId: "ecwl-finapop-stage",
  storageBucket: "ecwl-finapop-stage.appspot.com",
  messagingSenderId: "1076732296640",
  appId: "1:1076732296640:web:f064ab156e3cd825d05c25",
  measurementId: "G-YQT40JL8TY"
};

const firebaseConfigMcfStage = {
  apiKey: "AIzaSyDouUxwQimXs0LifdXONaEU0FzVUq7fX1w",
  authDomain: "ecwl-mcf-stage.firebaseapp.com",
  projectId: "ecwl-mcf-stage",
  storageBucket: "ecwl-mcf-stage.appspot.com",
  messagingSenderId: "1008228032453",
  appId: "1:1008228032453:web:bb6811b9f73070696675ac",
  measurementId: "G-DKEYMT0413"
};

const firebaseConfigMcfPRD = {
  apiKey: "AIzaSyB5LAr23vQmws6ZY9YelkGRLhSPG7TTdK4",
  authDomain: "ecwl-mcf.firebaseapp.com",
  projectId: "ecwl-mcf",
  storageBucket: "ecwl-mcf.appspot.com",
  messagingSenderId: "1072625164825",
  appId: "1:1072625164825:web:d379ebc6e32ecd61cc4088",
  measurementId: "G-NERP5ZG4R2"
};

const firebaseConfigAgrocorpStage = {
  apiKey: "AIzaSyAvaFJjX0gLw144lczkk935lqIEbb2WSYY",
  authDomain: "ecwl-agrocorp-stage.firebaseapp.com",
  projectId: "ecwl-agrocorp-stage",
  storageBucket: "ecwl-agrocorp-stage.appspot.com",
  messagingSenderId: "837964443808",
  appId: "1:837964443808:web:02eb28cd1868c3ea5459c5",
  measurementId: "G-6ZC3XRJLFR"
};

const firebaseConfigMypartStage = {
  apiKey: "AIzaSyDPJ_8KXSl1QqmkRtkmIKIbaCO-dGPNzro",
  authDomain: "ecwl-mypart-stage.firebaseapp.com",
  projectId: "ecwl-mypart-stage",
  storageBucket: "ecwl-mypart-stage.appspot.com",
  messagingSenderId: "394335535453",
  appId: "1:394335535453:web:8f1e9b0c216fc6ef6568b4",
  measurementId: "G-ZE75MDZJ2L"
};

const firebaseConfigMypartPRD = {
  apiKey: "AIzaSyAmlsWKUjQ7aJvg6GKWWgHmrvPJW0cQKtU",
  authDomain: "ecwl-mypart.firebaseapp.com",
  projectId: "ecwl-mypart",
  storageBucket: "ecwl-mypart.appspot.com",
  messagingSenderId: "25315688056",
  appId: "1:25315688056:web:3933de16bd6bc23cb1b78d",
  measurementId: "G-LQ73VS4ZWN"
};

const firebaseConfigMonefyStage = {
  apiKey: "AIzaSyBJ0BVXoqqObUWJk-RRJZOv02Ru7Szo_xY",
  authDomain: "ecwl-monefy-stage.firebaseapp.com",
  projectId: "ecwl-monefy-stage",
  storageBucket: "ecwl-monefy-stage.appspot.com",
  messagingSenderId: "443525868438",
  appId: "1:443525868438:web:0c2877a11eb13015ed4bb7",
  measurementId: "G-77HV72Q76K"
};

const firebaseConfigMnefyPRD = {
  apiKey: "AIzaSyBPU6607GoZvP9xKaV-5flzxg9NpjoHraA",
  authDomain: "ecwl-monefy.firebaseapp.com",
  projectId: "ecwl-monefy",
  storageBucket: "ecwl-monefy.appspot.com",
  messagingSenderId: "759288149530",
  appId: "1:759288149530:web:e40831e433f50e317c83a3",
  measurementId: "G-8L62VBT7DG"
};

const firebaseConfigReuninvestStage = {
  apiKey: "AIzaSyCuR4sJHup02Zpgcs923vjJEN-65md5Y1s",
  authDomain: "ecwl-reuninvest-stage.firebaseapp.com",
  projectId: "ecwl-reuninvest-stage",
  storageBucket: "ecwl-reuninvest-stage.appspot.com",
  messagingSenderId: "424549877858",
  appId: "1:424549877858:web:82faa5b25eb5929c3b293b",
  measurementId: "G-EP2S4JVZCN"
};

const firebaseConfigCaishenStage = {
  apiKey: "AIzaSyB1UMq2CIXdPr5Kqg7L9Kyadm2_70U6WJg",
  authDomain: "ecwl-caishen-stage-ea1af.firebaseapp.com",
  projectId: "ecwl-caishen-stage-ea1af",
  storageBucket: "ecwl-caishen-stage-ea1af.appspot.com",
  messagingSenderId: "247150616321",
  appId: "1:247150616321:web:598d8617f73e9ec3fd4500",
  measurementId: "G-RG2T14MDFK"
};

const firebaseConfigCaishenPRD = {
  apiKey: "AIzaSyBv_MvUcNF9L9BCNhoQF0leZ8w7l9pdWKM",
  authDomain: "ecwl-caishen-5a9dc.firebaseapp.com",
  projectId: "ecwl-caishen-5a9dc",
  storageBucket: "ecwl-caishen-5a9dc.appspot.com",
  messagingSenderId: "814594858708",
  appId: "1:814594858708:web:f15f6db73650bfb2a3e541",
  measurementId: "G-7GTM874XVR"
};

const firebaseConfigDpPrd = {
  apiKey: "AIzaSyDnOAvTmgjmw2MzJFNUkjnkl-ynrpnUzJE",
  authDomain: "ecwl-dp.firebaseapp.com",
  projectId: "ecwl-dp",
  storageBucket: "ecwl-dp.appspot.com",
  messagingSenderId: "115819037941",
  appId: "1:115819037941:web:47d02e7d028cce3e3071bc",
  measurementId: "G-FLVZN7TFCR"
};

const firebaseConfigFinapopPrd = {
  apiKey: "AIzaSyBjgYe-qJpd8FhFqahHoDib7LIMRpmBWGE",
  authDomain: "ecwl-finapop.firebaseapp.com",
  projectId: "ecwl-finapop",
  storageBucket: "ecwl-finapop.appspot.com",
  messagingSenderId: "575918612143",
  appId: "1:575918612143:web:4a32b88c9d2983b6803e5a",
  measurementId: "G-ZE55QT3BZB"
};

const firebaseConfigCustoReal = {
  apiKey: "AIzaSyAhBbqVtr3M5DbkaIfYc4eOzTuZJIPer8w",
  authDomain: "ecwl-custoreal.firebaseapp.com",
  projectId: "ecwl-custoreal",
  storageBucket: "ecwl-custoreal.appspot.com",
  messagingSenderId: "328181816379",
  appId: "1:328181816379:web:bffaa5c67a10656997d01e",
  measurementId: "G-521EJGMMGW"
};

export const initializeFirebaseApp = (buildApp) => {
  let firebaseConfig = {};
  if (process.env.VUE_APP_ENVIRONMENT == 'equity-dsv') {
    firebaseConfig = firebaseConfigEquityDSV;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'p2-dsv') {
    firebaseConfig = firebaseConfigP2DSV;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'p3-dsv') {
    firebaseConfig = firebaseConfigP3DSV;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'p1-demo') {
    firebaseConfig = firebaseConfigP1Demo;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'p2-demo') {
    firebaseConfig = firebaseConfigP2Demo;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'p3-demo') {
    firebaseConfig = firebaseConfigP3Demo;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'demo') {
    firebaseConfig = firebaseConfigDEMO;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'doacao-oficial') {
    firebaseConfig = firebaseConfigDoacaoOficial;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'icoowner') {
    firebaseConfig = firebaseConfigICoOwner;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'icoowner-stage') {
    firebaseConfig = firebaseConfigICoOwnerStage;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'dp-stage') {
    firebaseConfig = firebaseConfigDPStage;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'finapop-stage') {
    firebaseConfig = firebaseConfigFinapopStage;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'mcf-stage') {
    firebaseConfig = firebaseConfigMcfStage;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'mcf-prd') {
    firebaseConfig = firebaseConfigMcfPRD;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'agrocorp-stage') {
    firebaseConfig = firebaseConfigAgrocorpStage;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'mypart-stage') {
    firebaseConfig = firebaseConfigMypartStage;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'mypart-prd') {
    firebaseConfig = firebaseConfigMypartPRD;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'monefy-stage') {
    firebaseConfig = firebaseConfigMonefyStage;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'monefy-prd') {
    firebaseConfig = firebaseConfigMnefyPRD;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'reuninvest-stage') {
    firebaseConfig = firebaseConfigReuninvestStage;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'caishen-stage') {
    firebaseConfig = firebaseConfigCaishenStage;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'caishen-prd') {
    firebaseConfig = firebaseConfigCaishenPRD;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'dp-prd') {
    firebaseConfig = firebaseConfigDpPrd;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'finapop-prd') {
    firebaseConfig = firebaseConfigFinapopPrd;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'custoreal-prd') {
    firebaseConfig = firebaseConfigCustoReal;
  }
  
  Firebase.initializeApp(firebaseConfig);
  Firebase.auth().onAuthStateChanged(() => {
    buildApp();
  });
};

export const database = () => Firebase.firestore();

export const collectionRef = (name) => {
  return firebase.firestore().collection(name);
};

export const currentUser = () => {
  return firebase.auth().currentUser;
};

export const currentUserId = () => {
  const user = firebase.auth().currentUser;
  return user ? user.uid : undefined;
};
