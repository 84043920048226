<script>
import Vue from 'vue';

import { getModalidadePlataforma, TIPO_PLATAFORMA } from '@/helpers/tipoPlataformaHelper';
import getTransactions from '@/functions/getTransactions';
import ordersService from '@/services/Orders/ordersService';
import settingsService from '@/services/Settings/settingsService';
import financingService from '@/services/Financing/financingService';

import { DoubleBounce } from 'vue-loading-spinner';

import moment from 'moment';

import HelperPlugin from '@/plugins/HelperPlugin';
Vue.use(HelperPlugin);

import XLSX from 'xlsx';

import { vueTopprogress } from 'vue-top-progress';

export default {
  data() {
    return {
      plataformaType: TIPO_PLATAFORMA,
      nomePlataforma: '',
      transacoes: [],
      transacoes_excel: [],
      financing: {},
      vencimentoOferta: '',
      dataEncerramentoOperacao: '',
      isLoading: true,
      filtroSomenteAutorizado: false,
      filtroSomentePago: false,
      filtroSomentePendente: false
    };
  },
  components: {
    vueTopprogress,
    DoubleBounce
  },
  computed: {
    tipoPlataforma() {
      return getModalidadePlataforma();
    },
    isModalidadeEquity(){
      return (this.tipoPlataforma === this.plataformaType.P1) || (getModalidadePlataforma() === this.plataformaType.P3);
    },
    isP1(){
      return (this.tipoPlataforma  === this.plataformaType.P1);
    },
    isDoacao(){
      return (this.tipoPlataforma === this.plataformaType.DOACAO);
    },
    isP3(){
      return (this.tipoPlataforma  === this.plataformaType.P3);
    },
    routeId() {
      return this.$route.params.id;
    },
    routeType() {
      return this.$route.params.type;
    },
    vencimentoOferta() {
      return "--";
    },
  },
  mounted() {
    this.loadPrivateSettings();
    this.loadFinancingFullData();
    this.financing = this.$CWLNewFinanciamentoPadrao();
    this.loadTransactions();     
  },
  methods: {
    async loadPrivateSettings() {
      try {
        let privateSettings = await settingsService.getPrivateSettings();
        this.nomePlataforma = privateSettings.platformName || 'ECWL';

      } catch (error) {
        console.error('Erro ao obter configurações privadas:', error);
      }
    }, 
    async loadFinancingFullData() {
      let _financing = await financingService.findById(this.routeId);
      if (_financing.fields["Encerramento da Oferta"] && _financing.fields["prazo_oferta"]) {
        let dataEncerramento = _financing.fields["Encerramento da Oferta"].value;
        let mesesAdicionar = _financing.fields["prazo_oferta"].value;

        let _meses = parseInt(mesesAdicionar, 10);
        let _data = moment(dataEncerramento, "YYYY-MM-DD");

        this.vencimentoOferta =_data.add(_meses, 'months').format("DD/MM/YYYY");
      }

    },
    async loadTransactions() {
      try {
        this.transacoes = [];
        let that = this;
        const financingId = this.routeId;
        const accountKind = this.getAccountKind();
        let transacoes_to_map = await getTransactions(financingId, accountKind);

        this.transacoes = await Promise.all(transacoes_to_map.map(async (transacao) => {
          let orders = await ordersService.getOrdersByFieldEqualsTo('transacao_id', transacao.IdTransaction);
          var order = {
            pessoa: {
              documento: {
                numero: '--'
              }
            },
            ccb: {
              numero_ccb: '--',
              data_ccb: '--',
              data_vencimento: '--',
              valor_bruto: 0,
              valor_cad: 0,
              valor_iof: 0,
              valor_liquido: 0,
              valor_juro_am: 0,
              taxa_safe2pay: 0
            }
          };
          if ( orders.length > 0) {

            var orderFirst = orders[0];

            if (orderFirst.ccb && orderFirst.ccb.simulacao && orderFirst.ccb.simulacao.payload) {
                order.ccb.data_ccb = moment(orderFirst.ccb.simulacao.payload.DataDeLiberacao, "YYYY/MM/DD").format('DD/MM/YYYY');
                order.ccb.data_vencimento = moment(orderFirst.ccb.simulacao.payload.DataDeVencimento, "YYYY/MM/DD").format('DD/MM/YYYY');
                order.ccb.valor_bruto = orderFirst.ccb.simulacao.payload.ValorBruto || 0;
                order.ccb.valor_cad = orderFirst.ccb.simulacao.payload.ValorDaCAD;
                order.ccb.valor_iof = orderFirst.ccb.simulacao.response.IOF_NF;
                order.ccb.valor_liquido = orderFirst.ccb.simulacao.payload.ValorLiquido.toFixed(2);
                order.ccb.valor_juros_am = orderFirst.ccb.simulacao.response.CET_NF_MENSAL || 0;
                order.ccb.taxa_safe2pay = orderFirst.ccb.taxa_safe2pay || 0;

                if (that.dataEncerramentoOperacao.length == 0) {
                  that.dataEncerramentoOperacao = moment(orderFirst.ccb.simulacao.payload.DataDeLiberacao, "YYYY/MM/DD").format('DD-MM-YYYY') ||  "--";
                }
            }
            //Para manter a compatibilidade do relatorio criado pensado em P3, bloco acima trata CCB e valores de simulação
            //aqui fazer tratamentos conhecidos para P1 e não impactar P3 ja em funcionamento por
            if (this.isP1) {
              order.ccb.data_ccb = moment(orderFirst.order_date, "YYYY/MM/DD").format('DD/MM/YYYY');
              order.ccb.data_vencimento = this.vencimentoOferta;
              order.ccb.valor_liquido = parseFloat(transacao.Amount).toFixed(2) - parseFloat(transacao.TaxValue).toFixed(2);
            }

            if ( that.financing.name.length == 0 ) {
              that.financing.name = orderFirst.item.description;
            }
          }

          transacao.order = order;

          //Para manter a compatibilidade do relatorio criado pensado em P3, bloco acima trata CCB e valores de simulação
          //aqui fazer tratamentos conhecidos para P1 e não impactar P3 ja em funcionamento por
          if (this.isP1) {
            that.transacoes_excel.push({
              ID_SAFE2PAY: transacao.IdTransaction,
              STATUS_SAFE2PAY: transacao.Message,
              NOME_INVESTIDOR: transacao.Customer.Name,
              CPF_INVESTIDOR: transacao.Customer.Identity,
              EMAIL_INVESTIDOR: transacao.Customer.Email,
              CONTRATO: order.ccb.numero_ccb,
              DATA_OPERACAO: order.ccb.data_ccb,
              DATA_PAGAMENTO: this.dataPagamento(transacao.PaymentDate),
              DATA_VENCIMENTO: order.ccb.data_vencimento,
              VALOR_APORTE: transacao.Amount,
              TAXA_SAFE2PAY: transacao.TaxValue,
              VALOR_LIQUIDO: order.ccb.valor_liquido
            });
          } else {
            that.transacoes_excel.push({
              ID_SAFE2PAY: transacao.IdTransaction,
              // VALOR_APORTE: transacao.Amount,
              //  TAXA_SAFE2PAY: order.ccb.taxa_safe2pay,
              STATUS_SAFE2PAY: transacao.Message,
              NOME_INVESTIDOR: transacao.Customer.Name,
              CPF_INVESTIDOR: transacao.Customer.Identity,
              EMAIL_INVESTIDOR: transacao.Customer.Email,
              CCB: order.ccb.numero_ccb,
              DATA_CCB: order.ccb.data_ccb,
              DATA_VENCIMENTO: order.ccb.data_vencimento,
              VALOR_BRUTO: parseFloat(order.ccb.valor_bruto),
              VALOR_CAD: parseFloat(order.ccb.valor_cad),
              VALOR_IOF: parseFloat(order.ccb.valor_iof),
              VALOR_LIQUIDO: parseFloat(order.ccb.valor_liquido)
            });            
          }

          return transacao;
        }));

        this.transacoes_swap = this.transacoes;
        this.isLoading = false;
      } catch (error) {
        console.error('load transactions error', error);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

      }
    },
    getAccountKind() {
      let accountKind;
      if (this.routeType == 'campanha') {
        accountKind = 'beneficiary';
      } else if (this.routeType == 'cwl') {
        accountKind = 'platform';
      } else if (this.routeType == 'social-agent') {
        accountKind = 'agent';
      } else if (this.routeType == 'marketplace') {
        accountKind = 'marketplace';
      } else {
        throw new Error('Tipo de transação não suportado!');
      }
      return accountKind;
    },
    splitName(transaction) {
      const splits = transaction.Splits || [];
      return splits.length > 0 ? splits[splits.length - 1].Name : '';
    },
    splitAmount(transaction) {
      const splits = transaction.Splits || [];
      return splits.length > 0 ? splits[splits.length - 1].Amount : '';
    },
    dataPagamento(dateString){
      const date = moment(dateString, 'YYYY-MM-DD', true);
      
      if (!date.isValid()) {
        return dateString; 
      }
      return date.format('DD/MM/YYYY');
    },
    downloadExcel() {
      let nomeArquivo = this.nomePlataforma + "_" +  this.financing.name.replace(" ", "_").replace(" ", "_").replace(" ", "_").replace(" ", "_").replace(" ", "_") + "_" + this.dataEncerramentoOperacao + ".xlsx";
      const data = XLSX.utils.json_to_sheet(this.transacoes_excel);

      let columnsToFormat = this.isP1 ? ['J', 'K', 'L'] : ['I', 'J', 'K', 'L'];

      columnsToFormat.forEach((customCol) => {
        for (let i = 2; i <= this.transacoes_excel.length + 1; i++) {
          data[customCol + i].z = 'R$ #,##0.00';
        }
      });

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, 'Planilha1');
      XLSX.writeFile(wb, nomeArquivo);
    },
    checkHabilitarSomenteAutorizado() {
      this.filtroSomenteAutorizado = !this.filtroSomenteAutorizado;
      this.reloadTransacoes();
    },
    checkHabilitarSomentePendente() {
      this.filtroSomentePendente = !this.filtroSomentePendente;
      this.reloadTransacoes();
    },
    checkHabilitarSomentePago() {
      this.filtroSomentePago = !this.filtroSomentePago;
      this.reloadTransacoes();
    },
    reloadTransacoes() {
      this.transacoes = [];
      this.transacoes_swap.map((transacao) => {

        if (this.filtroSomenteAutorizado || this.filtroSomentePago || this.filtroSomentePendente) {
          if (this.filtroSomenteAutorizado && transacao.Message === 'Autorizado') {
            this.transacoes.push(transacao)
          } 

          if (this.filtroSomentePago && transacao.Message === 'Pago') {
            this.transacoes.push(transacao)
          }

          if (this.filtroSomentePendente && transacao.Message === 'Pendente') {
            this.transacoes.push(transacao)
          }
        } else {
          this.transacoes.push(transacao)
        }

      });
    }
  },
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card>
          <div v-if="isLoading" class="loading-spinner">
            <double-bounce></double-bounce>
          </div>
          <div class="row" v-if="!isLoading">
            <div class="col-3">
              <input
                  name="filtro_somente_autorizado"
                  ref="filtro_somente_autorizado"
                  type="checkbox"
                  v-model="filtroSomenteAutorizado"
                  @click="checkHabilitarSomenteAutorizado"
              />
              <label
                  for="filtro_somente_autorizado"
                  style="margin-left: 5px;"
                  @click="checkHabilitarSomenteAutorizado"
              >
                  FILTRAR SOMENTE AUTORIZADO
              </label>
            </div>
            <div class="col-3">
              <input
                  name="filtro_somente_pago"
                  ref="filtro_somente_pago"
                  type="checkbox"
                  v-model="filtroSomentePago"
                  @click="checkHabilitarSomentePago"
              />
              <label
                  for="filtro_somente_pago"
                  style="margin-left: 5px;"
                  @click="checkHabilitarSomentePago"
              >
                  FILTRAR SOMENTE PAGO
              </label>
            </div>
            <div class="col-3">
              <input
                  name="filtro_somente_pendente"
                  ref="filtro_somente_pendente"
                  type="checkbox"
                  v-model="filtroSomentePendente"
                  @click="checkHabilitarSomentePendente"
              />
              <label
                  for="filtro_somente_pendente"
                  style="margin-left: 5px;"
                  @click="checkHabilitarSomentePendente"
              >
                  FILTRAR SOMENTE PENDENTE
              </label>
            </div>
            <br><br>
            <div class="col-12">
              <div style="overflow: overlay !important;">
                <table class="table table-hover">
                  <thead class="thead-light">
                    <tr style="text-align: center;">
                      <th>ID SAFE2PAY</th>
                      <th>VALOR APORTE</th>
                      <th>TAXA SAFE2PAY</th>
                      <th>STATUS SAFE2PAY</th>
                      <th>NOME INVESTIDOR</th>
                      <th>CPF INVESTIDOR</th>
                      <th>EMAIL INVESTIDOR</th>
                      <th v-if="isP3">CCB</th>
                      <th v-else>CONTRATO</th>
                      <th v-if="isP3">DATA CCB</th>
                      <th v-else>DATA OPERACAO</th>
                      <th>DATA VENCIMENTO</th>
                      <th v-if="isP3">VALOR BRUTO</th>
                      <th v-if="isP3">VALOR CAD</th>
                      <th v-if="isP3">VALOR IOF</th>
                      <th>VALOR LIQUIDO</th>
                      <!-- <th>VALOR JUROS A.M.</th> -->
                      <th v-if="isP3">Reference</th>
                      <th>DATA PAGAMENTO</th>
                      <th v-if="isP3">TaxValue</th>
                      <th v-if="isP3">Split Name</th>
                      <th v-if="isP3">Split Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in transacoes" :key="index">
                      <td>{{ item.IdTransaction }}</td>
                      <td>{{ item.Amount | currency }}</td>
                      <td>{{ item.TaxValue | currency }}</td>
                      <td>{{ item.Message }}</td>
                      <td>{{ item.Customer.Name }}</td>
                      <td>{{ item.Customer.Identity }}</td>
                      <td>{{ item.Customer.Email }}</td>
                      <td>{{ item.order.ccb.numero_ccb }}</td>
                      <td>{{ item.order.ccb.data_ccb }}</td>
                      <td>{{ item.order.ccb.data_vencimento }}</td>
                      <td v-if="isP3">{{ item.order.ccb.valor_bruto | currency }}</td>
                      <td v-if="isP3">{{ item.order.ccb.valor_cad | currency }}</td>
                      <td v-if="isP3">{{ item.order.ccb.valor_iof | currency }}</td>
                      <td>{{ item.order.ccb.valor_liquido | currency }}</td>
                      <!-- <td>{{ item.order.ccb.valor_juros_am | currency }}</td> -->
                      <td v-if="isP3">{{ item.Reference }}</td>
                      <td>{{ dataPagamento(item.PaymentDate) }}</td>
                      <td v-if="isP3">{{ item.TaxValue }}</td>
                      <td v-if="isP3">{{ splitName(item) }}</td>
                      <td v-if="isP3">{{ splitAmount(item) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="row" v-if="!isLoading">
            <button
              type="button"
              class="btn btn-sm"
              style="padding: 10px"
              @click="downloadExcel"
            >
              Download Excel
            </button>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
