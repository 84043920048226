<script>
import PieChart from '@/components/Charts/PieChart';
import * as chartConfigs from '@/components/Charts/config';
import LimitsTable from '@/components/Limits/LimitsTable';
import CampaignCard from '@/components/Campaign/CampaignCard';
import { vueTopprogress } from 'vue-top-progress';
import pessoasService from '@/services/Pessoas/pessoasService';
import processInvestorOrder from '@/services/Functions/pessoasFunctions'
import ordersService from '@/services/Orders/ordersService';
import financingService from '@/services/Financing/financingService';
import settingsService from '@/services/Settings/settingsService';

import { PessoaCaracteristica, CWLStatusCampanha, CWLInvestimentoDashboard } from '@/store/cwl_enums';

const tableColumns = [
  'Investimentos Financeiros',
  'Renda Bruta Anual',
  'Limite Anual ',
];

export default {
  components: {
    PieChart,
    LimitsTable,
    vueTopprogress,
    CampaignCard,
  },
  data() {
    return {
      chartOptions: {
        hoverBorderWidth: 20,
      },
      chartData: {
        labels: ['Nesta Plataforma', 'Outras Plataformas'],
        datasets: [
          {
            backgroundColor: ['#42b883', '#1d8cf8'],
            data: [],
          },
        ],
        totalInvestments: 0,
      },
      campaignStatusChartData: {
        labels: [
          'Em Andamento',
          'Finalizado com Sucesso',
          'Finalizado sem Sucesso',
        ],
        datasets: [
          {
            backgroundColor: ['#c376e0', '#344563'],
            data: [],
          },
        ],
        totalInvestments: 0,
      },
      collectedChartData: {
        labels: ['Pago', 'Aguardando Confirmação de Pagamento'],
        datasets: [
          {
            backgroundColor: ['#c376e0', '#344563'],
            data: [],
          },
        ],
        totalInvestments: 0,
      },
      table: {
        title: 'Limites de Investimentos',
        columns: [...tableColumns],
      },
      limits: [],
      limitsExamples: [],
      investorInformations: [],
      person: {},
      orders: [],
      totalPercentage: 0,
      calculatedPaid: 0,
      calculatedReserved: 0,
      updatedFinancings: [],
      allOrders: [],
      months: {},
      chartAnnualLimit: 40000,

      quantidadCampanhasParaAnalisar: 0,
      dashboardType: process.env.VUE_APP_TYPE,
      dashboardSubType: process.env.VUE_APP_SUBTYPE,
      rootSite: process.env.VUE_APP_ROOT_SITE,
      projectSitePath: process.env.VUE_APP_SITE_PROJECT_PATH,
      ofertaTemp: {},
      investimentos_nesta_plataforma: 0
    };
  },
  computed: {
    enableRTL() {
      return this.$route.query.enableRTL;
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    bigLineChartCategories() {
      return this.$t('dashboard.chartCategories');
    },
    loggedUser() {
      return this.$store.getters.isLoggedIn;
    },
    userClaim() {
      return (this.$store.getters.userClaim.role || '').toLowerCase();
    },
    loggedPessoa() {
      return this.$store.getters.getPessoa;
    },
    isAdminGestorCurador() {
      return (
        this.userClaim == PessoaCaracteristica.Curador ||
        this.userClaim == PessoaCaracteristica.Gestor ||
        this.userClaim == PessoaCaracteristica.Master
      );
    },
    isCurador() {
      return this.userClaim == PessoaCaracteristica.Curador;
    },
    goToProfile() {
      return this.$route.query.gotoprofile;
    },
  },
  methods: {
    async delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async recalcularOrdens(pessoaId) {
      await processInvestorOrder(pessoaId).catch((e) => {
        console.warn("Erro ignorado ao recalcular ordens do investidor", e);
      });
    },

    async getOfertaTemp() {
      const ofertaStore = this.$store.getters.getOferta;
      if (ofertaStore) {
        const idOfertaStore = ofertaStore.oferta_id;
        if (idOfertaStore) {
          const ofertaFromFirebase = await financingService.get(idOfertaStore);

          this.ofertaTemp = ofertaFromFirebase.data();
          this.ofertaTemp.id = ofertaFromFirebase.id;
        }
      }
    },
    async goToOfertaSite() {
      let _projectPath = '/projetos';
      if (this.projectSitePath) {
        const _id = this.ofertaTemp ? this.ofertaTemp.id || '' : '';
        _projectPath = this.projectSitePath.replace('{id}', _id);
      }
      window.top.location.href = this.rootSite + _projectPath;
    },
    async getFinancingToAnalyze() {
      const financings = await financingService.getAllByStatus(
        CWLStatusCampanha.AguardandoAnalise,
      );
      this.quantidadCampanhasParaAnalisar = financings.size;
    },
    async getLimits() {
      const dashboardLimits = await settingsService.getDashboardLimits();

      this.limitsExamples = dashboardLimits.examples;
      this.limits = dashboardLimits.items;

      this.getInvestorInformations();
    },
    async getInvestorInformations() {
      this.recalcularOrdens(this.loggedPessoa.pessoa_id);

      const user = await pessoasService.getPessoaBy({id: this.loggedPessoa.pessoa_id});
      this.investorInformations.push(user);
      this.person = user;
    },
    async initInvestmentsChart() {
      //Aguardando carregar a pessoa para não gerar grafico em branco
      let attempts = 3;
      while ((!this.person.dados_complementares || !this.person.dados_complementares.perfil) && attempts > 0) {
        await this.delay(200); // Aguarda 200ms por ciclo
        attempts--;
      }

      if (this.person.dados_complementares && this.person.dados_complementares.perfil) {
        const {
          investimentos_outras_plataformas
        } = this.person.dados_complementares.perfil;

        const investimentos_nesta_plataforma_preformatted = parseFloat(
          this.investimentos_nesta_plataforma,
        )
          .toFixed(2)
          .replace('.', ',');
        const investimentos_nesta_plataforma_formatted =
          'R$ ' +
          investimentos_nesta_plataforma_preformatted
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        const investimentos_outras_plataformas_preformatted = parseFloat(
          investimentos_outras_plataformas,
        )
          .toFixed(2)
          .replace('.', ',');
        const investimentos_outras_plataformas_formatted =
          'R$ ' +
          investimentos_outras_plataformas_preformatted
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        const chartData = {
          datasets: [
            {
              backgroundColor: ['#42b883', '#1d8cf8'],
              data: [
                this.investimentos_nesta_plataforma,
                investimentos_outras_plataformas,
              ],
            },
          ],
          labels: ['Nesta Plataforma', 'Outras Plataformas'],
        };

        this.chartData = chartData;
        this.chartData.totalInvestments = (investimentos_outras_plataformas ? investimentos_outras_plataformas : 0) + this.investimentos_nesta_plataforma;
        this.chartData.totalInvestments = this.chartData.totalInvestments ? this.chartData.totalInvestments : 0;
      }
    },
    async getOrders() {
      this.orders = await ordersService.getOrdersByUser(
        this.loggedPessoa.pessoa_id,
      );

      this.allOrders = this.orders;

      const unique = [
        ...new Map(
          this.orders.map((item) => [item.financing_id, item]),
        ).values(),
      ];

      this.orders = unique;

      this.generateCampaignCards();
      if (this.dashboardType == 'equity') {
        this.initPaidChart();
      }

      this.createHorizontalBarChart();
      this.createBarChart();
    },
    async generateCampaignCards() {
      const orders = this.orders;
      const that = this;

      for (const order of orders) {
        const returnedFinancing = await financingService.get(
          order.financing_id,
        );

        const updatedFinancing = returnedFinancing.data();

        updatedFinancing.id = returnedFinancing.id;

        that.updatedFinancings.push(updatedFinancing);
      }

      this.initCampaignStatusChart();
    },
    initCampaignStatusChart() {
      const successfullyFinished = [];
      const unsuccessfullyFinished = [];
      const inProgress = [];

      if (this.updatedFinancings.length) {
        this.updatedFinancings.map((campanha) => {
          if (campanha.status === CWLStatusCampanha.ConcluidaComSucesso) {
            successfullyFinished.push(campanha);
          } else if (
            campanha.status === CWLStatusCampanha.ConcluidaSemSucesso
          ) {
            unsuccessfullyFinished.push(campanha);
          } else {
            inProgress.push(campanha);
          }
        });
      }

      const chartData = {
        datasets: [
          {
            backgroundColor: ['#2a8ef7', '#68e497', '#ed415e'],
            data: [
              inProgress.length,
              successfullyFinished.length,
              unsuccessfullyFinished.length,
            ],
          },
        ],
        labels: [
          'Em Andamento',
          'Finalizado com Sucesso',
          'Finalizado sem Sucesso',
        ],
      };

      this.campaignStatusChartData = chartData;
    },
    async initPaidChart() {
      let paidOrders = [];
      let reservedOrders = [];
      let pendenteOrders = [];

      const privateSettings = await settingsService.getPrivateSettings();
      const investimentoDashboard = privateSettings.investimento_dashboard ? privateSettings.investimento_dashboard : CWLInvestimentoDashboard.PagosPendentes;
      this.allOrders.find((order) => {
        if ( investimentoDashboard == CWLInvestimentoDashboard.PagosPendentes ) {
          if (order.status == "Pago") {
            paidOrders.push(order.item.value * order.item.quantity);
          } else if (order.status == "Pendente") {
            pendenteOrders.push(order.item.value * order.item.quantity);
          } else {
            reservedOrders.push(order.item.value * order.item.quantity);
          }
        }
        else if (investimentoDashboard == CWLInvestimentoDashboard.Pagos && order.status == "Pago") {
          paidOrders.push(order.item.value * order.item.quantity);
        }
      });

      paidOrders = paidOrders.reduce((a, b) => a + b, 0);
      reservedOrders = reservedOrders.reduce((a, b) => a + b, 0);
      pendenteOrders = pendenteOrders.reduce((a, b) => a + b, 0);

      const chartData = {
        datasets: [
          {
            backgroundColor: ['#c376e0', '#344563'],
            data: [paidOrders, pendenteOrders],
          },
        ],
        labels: ['Pago', 'Aguardando Confirmação de Pagamento'],
      };

      this.collectedChartData = chartData;
      this.collectedChartData.totalInvestments = paidOrders + reservedOrders + pendenteOrders;
      
      if ( investimentoDashboard == CWLInvestimentoDashboard.PagosPendentes ) {
        this.investimentos_nesta_plataforma = paidOrders + reservedOrders + pendenteOrders;
      }
      else if (investimentoDashboard == CWLInvestimentoDashboard.Pagos) {
        this.investimentos_nesta_plataforma = paidOrders;
      }

      if (this.dashboardType == 'equity') {
        this.initInvestmentsChart();
      }
    },
    calcValues(campanha) {
      this.calculatedReserved = campanha.totais.porcentagem_reservado;
      this.calculatedPaid = campanha.totais.porcentagem_investido;
      this.calculatedPendente = campanha.totais.porcentagem_pendente;
      this.totalPercentage = campanha.totais.porcentagem_total;

      return this.totalPercentage;
    },
    createHorizontalBarChart() {
      const horizontalLabels = [];
      const horizontalData = [];

      if (this.updatedFinancings.length) {
        this.updatedFinancings.map((campanha) => {
          horizontalLabels.push(campanha.name.slice(0, 12) + '..');

          const percentageItem = this.calcValues(campanha);
          horizontalData.push(parseFloat(percentageItem).toFixed(2));
        });
      }

      const textToShow =
        this.dashboardType == 'doacao'
          ? 'Progresso das Campanhas Investidas'
          : 'Progresso das Ofertas Investidas';

      new Chart(document.getElementById('bar-chart-horizontal'), {
        type: 'horizontalBar',
        data: {
          labels: horizontalLabels,
          datasets: [
            {
              label: 'Porcentagem',
              backgroundColor: ['#52b884'],
              data: horizontalData,
            },
          ],
        },
        options: {
          ...chartConfigs.barChartOptions,
          legend: { display: false },
          title: {
            display: true,
            text: textToShow,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  min: 0,
                  max: 100,
                },
              },
            ],
          },
        },
      });
    },
    async createBarChart() {
      const ctx = document.getElementById('canvas');

      if (
        this.person &&
        this.person.dados_complementares &&
        this.person.dados_complementares.perfil
      ) {
        const {
          renda_bruta_anual,
          investimentos_financeiros,
          investimentos_outras_plataformas,
          investimentos_nesta_plataforma,
        } = this.person.dados_complementares.perfil;

        this.currentLimit = {};

        this.limits.map((limit) => {
          if (
            renda_bruta_anual <= limit.annualGrossIncome &&
            investimentos_financeiros <= limit.financingInvestments
          ) {
            this.currentLimit = this.limits[0];
          } else {
            this.currentLimit = this.limits[1];
          }
        });

        this.chartAnnualLimit =
          this.currentLimit.annualLimit -
          (investimentos_outras_plataformas + investimentos_nesta_plataforma);
      }

      Chart.pluginService.register({
        afterDraw: function (chart) {
          if (typeof chart.config.options.lineAt != 'undefined') {
            let lineAt = chart.config.options.lineAt;
            const ctxPlugin = chart.chart.ctx;
            const xAxe = chart.scales[chart.config.options.scales.xAxes[0].id];
            const yAxe = chart.scales[chart.config.options.scales.yAxes[0].id];

            if (yAxe.min != 0) return;

            ctxPlugin.strokeStyle = 'red';
            ctxPlugin.beginPath();
            lineAt = (lineAt - yAxe.min) * (100 / yAxe.max);
            lineAt = ((100 - lineAt) / 100) * yAxe.height + yAxe.top;
            ctxPlugin.moveTo(xAxe.left, lineAt);
            ctxPlugin.lineTo(xAxe.right, lineAt);
            ctxPlugin.stroke();
          }
        },
      });

      const months = [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
      ];

      months.map((month) => {
        this.months[month] = 0;
      });
      const privateSettings = await settingsService.getPrivateSettings();
      const investimentoDashboard = privateSettings.investimento_dashboard ? privateSettings.investimento_dashboard : CWLInvestimentoDashboard.PagosPendentes;
      this.allOrders.map((order) => {
        const orderDate = new Date(order.order_date);
        const currentYear = orderDate.getFullYear();

        if (currentYear === new Date().getFullYear()) {
          const orderMonth = months[orderDate.getMonth()];

          var valorParaSomar = 0;
          if ( investimentoDashboard == CWLInvestimentoDashboard.PagosPendentes ) {
            valorParaSomar = order.item.value * order.item.quantity;
          }
          else if ( investimentoDashboard == CWLInvestimentoDashboard.Pagos && order.status == "Pago" ) {
            valorParaSomar = order.item.value * order.item.quantity;
          }
          const currentValue = this.months[orderMonth] + valorParaSomar;

          this.months[orderMonth] = currentValue;
        }
      });

      const chartData = Object.values(this.months);

      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: months,
          datasets: [
            {
              label: 'Valor Investido',
              backgroundColor: 'rgba(54, 162, 235, 0.2)',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1,
              borderDash: [],
              borderDashOffset: 0.0,
              data: chartData,
              callback: function (value, index, values) {
                console.log(value);
                return (
                  'R$' +
                  parseFloat(value)
                    .toFixed(2)
                    .replace('.', ',')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                );
              },
            },
          ],
        },
        options: {
          ...chartConfigs.barChartOptions,
          scales: {
            yAxes: [
              {
                ticks: {
                  min: 0,
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label(tooltipItem, data) {
                const label = data.datasets[tooltipItem.datasetIndex].label;
                const value =
                  'R$' +
                  parseFloat(tooltipItem.yLabel)
                    .toFixed(2)
                    .replace('.', ',')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                return `${label}: ${value}`;
              },
            },
          },
        },
      });
    },
  },
  mounted() {
    this.i18n = this.$i18n;
    if (this.enableRTL) {
      this.i18n.locale = 'ar';
      this.$rtl.enableRTL();
    }
    if ( this.goToProfile == '1' ) {
      this.$router.push('/profile');
    }
    if (!this.isAdminGestorCurador) {
      this.getLimits();
      this.getOrders();
    } else if (this.isCurador) {
      this.getFinancingToAnalyze();
    }
    if (this.dashboardType == 'equity') {
      this.getOfertaTemp();
    }
  },
  beforeDestroy() {
    if (this.$rtl.isRTL) {
      this.i18n.locale = 'en';
      this.$rtl.disableRTL();
    }
  },
  watch: {
    updatedFinancings() {
      if (!this.isAdminGestorCurador && this.dashboardType == 'equity') {
        // this.createHorizontalBarChart();
        // this.initCampaignStatusChart();
        // this.createBarChart();
      }
    },
  },
};
</script>

<template>
  <div>
    <div v-if="isCurador">
      <div class="row">
        <div class="col-12">
          <card>
            <h4>Bem vindo(a) {{ loggedPessoa.nome }}!</h4>
            <br />
            <span class="card-text">
              Você possui
              <router-link :to="`financing`" v-if="dashboardType == 'doacao'">
                {{ quantidadCampanhasParaAnalisar }} campanha(s)
              </router-link>
              <router-link :to="`financing`" v-if="dashboardType == 'equity'">
                {{ quantidadCampanhasParaAnalisar }} oferta(s)
              </router-link>
              para analisar.
            </span>
          </card>
        </div>
      </div>
    </div>
    <div>
      <div class="row" v-if="dashboardType === 'equity' && ofertaTemp.id">
        <div class="col-12">
          <a @click="goToOfertaSite()" href="#" title="" class="button-action">
            <card
              style="
                background-color: #288cf7;
                border-radius: 4px;
                text-align: center;
                color: #fff;
              "
            >
              Clique para continuar seu investimento
              <img
                :src="ofertaTemp.imageURL"
                style="height: 50px; margin-left: 10px; margin-right: 20px"
              />
              <span>
                <b>{{ ofertaTemp.name }}</b>
              </span>
            </card>
          </a>
        </div>
      </div>
      <div class="row" v-if="dashboardType === 'equity'">
        <div class="col-12">
          <card type="chart">
            <template slot="header">
              <div class="row">
                <div
                  class="col-sm-6"
                  :class="isRTL ? 'text-right' : 'text-left'"
                >
                  <h2 class="card-title">Linha do Tempo</h2>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas id="canvas" height="100%"></canvas>
            </div>
          </card>
        </div>
      </div>
      <div class="row" v-if="dashboardType === 'equity'">
        <div class="col-lg-4" :class="{ 'text-right': isRTL }">
          <card type="chart">
            <template slot="header">
              <h5 class="card-category">Total de Investimentos</h5>
              <h3 class="card-title">
                {{ chartData.totalInvestments | currency }}
              </h3>
            </template>
            <div class="chart-area pie-chart">
              <pie-chart
                :data="chartData"
                :options="chartOptions"
                :chart-data="chartData"
                ref="pieChart"
              />
            </div>
          </card>
        </div>

        <div class="col-lg-4" :class="{ 'text-right': isRTL }">
          <card type="chart">
            <template slot="header">
              <h5 class="card-category">Valor Aportado</h5>
              <h3 class="card-title">
                {{ collectedChartData.totalInvestments | currency }}
              </h3>
            </template>
            <div class="chart-area pie-chart">
              <pie-chart
                :data="collectedChartData"
                :options="chartOptions"
                :chart-data="collectedChartData"
                ref="pieChart"
              />
            </div>
          </card>
        </div>

        <div class="col-lg-4" :class="{ 'text-right': isRTL }">
          <card type="chart">
            <template slot="header">
              <h5 class="card-category">Andamento de suas</h5>
              <h3 class="card-title" v-if="dashboardType == 'doacao'">
                Campanhas
              </h3>
              <h3 class="card-title" v-if="dashboardType == 'equity'">
                <span v-if=" dashboardSubType != '588' ">CCBs Disponíveis</span>
                <span v-if=" dashboardSubType === '588' ">Ofertas Disponíveis</span> 
              </h3>
            </template>
            <div class="chart-area pie-chart">
              <pie-chart
                :data="campaignStatusChartData"
                :options="chartOptions"
                :chart-data="campaignStatusChartData"
                ref="pieChart"
              />
            </div>
          </card>
        </div>
      </div>

      <div class="row" v-if="dashboardType === 'equity'">
        <div class="col-lg-4" :class="{ 'text-right': isRTL }">
          <card type="chart">
            <template slot="header">
              <h5 class="card-category" v-if="dashboardType == 'doacao'">
                Progresso das Campanhas
              </h5>
              <h5 class="card-category" v-if="dashboardType == 'equity'">
                Progresso das Ofertas
              </h5>
            </template>
            <div class="chart">
              <canvas
                id="bar-chart-horizontal"
                width="100%"
                height="100%"
              ></canvas>
            </div>
          </card>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <card class="card" :header-classes="{ 'text-right': isRTL }">
            <h4
              slot="header"
              class="card-title"
              v-if="dashboardType == 'equity'"
            >
              {{ $t('dashboard.myInvestmentsTitle') }}
            </h4>
            <h4
              slot="header"
              class="card-title"
              v-if="dashboardType == 'doacao'"
            >
              {{ $t('dashboard.myContributionsTitle') }}
            </h4>
            <h4
              slot="header"
              class="card-category"
              v-if="dashboardType == 'equity'"
            >
              {{ $t('dashboard.myInvestmentsSubtitle') }}
            </h4>
            <h4
              slot="header"
              class="card-category"
              v-if="dashboardType == 'doacao'"
            >
              {{ $t('dashboard.myContributionsSubtitle') }}
            </h4>
            <h5
              class="card-title"
              v-if="updatedFinancings.length == 0 && dashboardType == 'doacao'"
            >
              Você ainda não fez doações 🙁
            </h5>
            <h5
              class="card-title"
              v-if="updatedFinancings.length == 0 && dashboardType == 'equity'"
            >
              Você ainda não fez aportes 🙁
            </h5>
            <div class="row campaign-list">
              <router-link
                :to="`campaign-details/${campanha.id}`"
                tag="div"
                class="card-column"
                v-for="(campanha, index) in updatedFinancings"
                :key="index"
              >
                <campaign-card :financing="campanha" :dashboard="true" />
              </router-link>
            </div>
          </card>
        </div>
      </div>

      <vue-topprogress ref="topProgress"></vue-topprogress>
    </div>
  </div>
</template>
