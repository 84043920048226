<script>
import ordersService from '@/services/Orders/ordersService';
import financingService from '@/services/Financing/financingService';
import logsService from '@/services/Logs/logsService';
import attachmentsService from '@/services/Attachments/attachmentsService';
import contratosInvestidorService from '@/services/ContratosInvestidor/contratosInvestidorService';
import socinalService from '@/services/Socinal/socinalService';
import fiduciaService from '@/services/Fiducia/fiduciaService';

import { vueTopprogress } from 'vue-top-progress';
import { DoubleBounce } from 'vue-loading-spinner';

import {
  BancarizadorIntegracaoID
} from '@/store/cwl_enums';

export default {
  components: {
    vueTopprogress,
    DoubleBounce,
  },
  data() {
    return {
      data: [],
      financing: {},
      isLoading: true,
      
      // Paginação
      page: 1,
      perPage: 10,
      pages: [],

      // Filtros
      searchBar: '',

      // Modal
      showModal: false,

      BancarizadorIntegracaoID: BancarizadorIntegracaoID,

      operacaoErrorMessage: "",
      operacaoErrorShown: false,
      operacaoEmAndamento: false,
      operacaoConcluida: false
    };
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    objId() {
      return this.$route.params.id;
    },
    displayedData() {
      return this.paginate(this.filteredData);
    },
    filteredData() {
      return this.data.filter(obj => {
        return(
             (obj.name ? obj.name : '').toLowerCase().includes(this.searchBar.toLowerCase())
        )
      });
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.data = [];

        this.financing = await financingService.findById(this.objId);
        
        const ordersFromService = await ordersService.getOrdersByFinancingId(
            this.objId,
        );
        await Promise.all(ordersFromService.map( async function(order) {
          var status = "pendente";
          var error_message = "";

          let contratosInvestidor = await contratosInvestidorService.getByOrderId(order.id);
          if ( contratosInvestidor.length > 1 ) {
            status = "erro";
            error_message = "Contrato Duplicado";
          }
          else if ( contratosInvestidor.length == 0 ) {
            status = "erro";
            error_message = "Sem Contrato";
          }
          else {
            let contratoInvestidor = contratosInvestidor[0];
            if ( contratoInvestidor.id ) {
              let assinantesDoContrato = contratoInvestidor.pessoas.filter(obj => {
                return obj.pessoa_tipo == "investidor" || obj.pessoa_tipo == "tomador" || obj.pessoa_tipo == "credor"
              });

              if ( assinantesDoContrato.length == 3 ) {
                status = "concluído";
              }
            }
          }
          if ( !order.ccb ) {
            order.ccb = {
              importacao: {
                  status: status,
                  error_message: error_message
              }
            };
          }
          else if ( !order.ccb.importacao ) {
            order.ccb.importacao = {
              status: status,
              error_message: error_message
            };
          }
        }));
        this.isLoading = false;
        this.data = ordersFromService;
    },

    async cancelarOperacaoSocinal(objeto) {
      let order = objeto.order;
      await socinalService
        .cancelarOperacao(objeto)
        .then(async function (result) {
          order.ccb.importacao.error_message = "";
          order.ccb.importacao.status = "cancelado";
          order.status = "Vencido";

          await ordersService.update(order.id, order);
        })
        .catch(async function (error) {
          order.ccb.importacao.status = "erro";
          order.ccb.importacao.error_message = error.message;
          await ordersService.update(order.id, order);
        });
    },
    async encerrarOperacaoSocinal(objeto) {
      let order = objeto.order;

      await socinalService
        .encerrarOperacao(objeto)
        .then(async function (result) {
          order.ccb.importacao.error_message = "";
          order.ccb.importacao.status = "concluído";

          await ordersService.update(order.id, order);
        })
        .catch(async function (error) {
          order.ccb.importacao.status = "erro";
          order.ccb.importacao.error_message = error.message;
          await ordersService.update(order.id, order);
        });
    },
    async cancelarOperacaoFiducia(objeto) {
      let order = objeto.order;

      await fiduciaService
        .cancelarOperacao(objeto)
        .then(async function (result) {
          order.ccb.importacao.error_message = "";
          order.ccb.importacao.status = "cancelado";
          order.status = "Vencido";

          await ordersService.update(order.id, order);
        })
        .catch(async function (error) {
          order.ccb.importacao.status = "erro";
          order.ccb.importacao.error_message = error.message;
          await ordersService.update(order.id, order);
        });
    },
    async encerrarOperacaoFiducia(objeto) {
      let order = objeto.order;

      await fiduciaService
      .encerrarOperacao(objeto)
        .then(async function (result) {
          let orderToUpdate = await ordersService.get(order.id);
          let dataOrderToUpdate = orderToUpdate.data();

          order.ccb.importacao.error_message = "";
          order.ccb.importacao.status = "concluído";

          dataOrderToUpdate.ccb.importacao.error_message = "";
          dataOrderToUpdate.ccb.importacao.status = "concluído";
          await ordersService.update(dataOrderToUpdate.id, dataOrderToUpdate);
        })
        .catch(async function (error) {
          let orderFromFirebase = await ordersService.get(order.id);
          let orderToUpdate = orderFromFirebase.data();

          order.ccb.importacao.status = "erro";
          order.ccb.importacao.error_message = error.message;

          orderToUpdate.ccb.importacao.error_message = error.message;
          orderToUpdate.ccb.importacao.status = "erro";
          
          await ordersService.update(orderToUpdate.id, orderToUpdate);
        });
    },

    async confirmarOperacaoInvestimento() {
      const that = this;
      this.isLoading = true;
      
      this.operacaoEmAndamento = true;
      this.operacaoConcluida = false;
      var ordersProcessadas = 0;
      await Promise.all(this.data.map(async function(order) {
        var importarOperacao = false;
        
        let contratosInvestidor = await contratosInvestidorService.getByOrderId(order.id);
        if ( !order.ccb ) {
          order.ccb = {
            importacao: {
              status: "erro",
              error_message: "Este aporte não possui número CCB."
            }
          };
          await ordersService.update(order.id, order);
        }
        else if ( !order.ccb.numero_ccb ) {
          order.ccb.importacao = {
            status: "erro",
            error_message: "Este aporte não possui número CCB."
          };
          await ordersService.update(order.id, order);
        }
        else if ( !order.ccb.simulacao ) {
          order.ccb.importacao = {
            status: "erro",
            error_message: "Este aporte não possui simulação."
          };
          await ordersService.update(order.id, order);
        }
        else if ( contratosInvestidor.length > 1 ) {
          order.ccb.importacao = {
            status: "erro",
            error_message: "Contrato Duplicado"
          };
          await ordersService.update(order.id, order);
        }
        else if ( contratosInvestidor.length == 0 ) {
          order.ccb.importacao = {
            status: "erro",
            error_message: "Sem Contrato"
          };
          await ordersService.update(order.id, order);
        }
        else if ( !order.ccb.importacao || order.ccb.importacao.status == "pendente" ) {
          importarOperacao = true;
          order.ccb.importacao = {
            status: "pendente",
            error_message: ""
          };
          await ordersService.update(order.id, order);
        }
        else if ( order.ccb.importacao && order.ccb.importacao.status == "erro" ) {
          importarOperacao = true;
        }

        const objeto = {
          financing: that.financing,
          order: order
        };
        if ( order.status == 'Pago' && order.ccb && order.ccb.importacao && importarOperacao ) {
          order.ccb.importacao.status = "executando";
          if ( that.financing.bancarizador.integracao_id == BancarizadorIntegracaoID.Socinal ) {
            that.encerrarOperacaoSocinal(objeto);
          }
          else if ( that.financing.bancarizador.integracao_id == BancarizadorIntegracaoID.Fiducia ) {
            that.encerrarOperacaoFiducia(objeto);
          }
        }
        else if ( order.status == 'Pendente' && order.ccb ) {
          order.ccb.importacao.status = "cancelando";
          if ( that.financing.bancarizador.integracao_id == BancarizadorIntegracaoID.Socinal ) {
            that.cancelarOperacaoSocinal(objeto);
          }
          else if ( that.financing.bancarizador.integracao_id == BancarizadorIntegracaoID.Fiducia ) {
            that.cancelarOperacaoFiducia(objeto);
          }
        }
      }));
      this.isLoading = false;
    },

    formatDate(date) {
      return this.$CWLFormatDate(date);
    },

    async downloadContratoCCB(order) {
      try {
        let documento = order.pessoa.documento.numero.replace(".", "").replace(".", "").replace("-", "").replace("/", "");
        let path = "ContratosCCB/" + order.financing_id + "/" + documento;
        let filename = "ContratoCCB_" + documento + "_" + order.ccb.numero_ccb + ".pdf";
        await attachmentsService.downloadAttachment(filename, path);
      }
      catch (e) {
        console.log(e);
      }
    },

    // Paginação
    setPages() {
      if (this.pages) {
        this.pages = [];
      }

      const numberOfPages = Math.ceil(this.data.length / this.perPage);

      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      const page = this.page;
      const perPage = this.perPage;

      const from = (page * perPage) - perPage;
      const to = (page * perPage);

      return data.slice(from, to);
    },
  },
  watch: {
    data() {
      this.setPages();
    },
  },
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card>

          <h5 slot="header" class="title">
              <router-link :to="'/edit-financing/' + objId" class="fa fa-arrow-left"></router-link>
              Ver oferta
          </h5>

          <div v-if="isLoading" class="loading-spinner">
            <double-bounce></double-bounce>
          </div>
          
          <!-- Filtros e Busca -->
          <!-- <div class="row">
            <div class="col-12">
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Para pesquisar, digite sua busca aqui..." v-model="searchBar" />
              </div>
            </div>
          </div> -->

          <!-- <div class="row">
            <div class="col-12" style="text-align: center;">
              <h1 style="color: red !important;" v-if="operacaoEmAndamento" class="modal-content-title">NÃO FECHE ESTA JANELA. ENCERRAMENTO EM ANDAMENTO.</h1>
              <h1 style="color: blue !important;" v-if="operacaoConcluida" class="modal-content-title">ENCERRAMENTO CONCLUÍDO. VERIFIQUE O STATUS DE CADA OPERAÇÃO.</h1>
            </div>
          </div> -->
          <!-- Listagem dos dados -->
          <div class="row" v-if="financing.bancarizador && !isLoading">
            <div class="col-12">
              <table class="table table-hover table-responsive">
                <thead class="thead-light">
                    <tr>
                        <th>Data</th>
                        <th>Nome</th>
                        <th>Valor</th>
                        <th>Status Pgto</th>
                        <th>CCB</th>
                        <th style="text-align: center">Status</th>
                        <th style="text-align: center">Contrato CCB</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in data" :key="index" style="cursor: default !important; height: 60px;">
                        <td>
                            {{ formatDate(item.order_date) }}
                        </td>
                        <td>
                            {{ item.pessoa.nome }}
                        </td>
                        <td>
                            {{ item.item.value | currency }}
                        </td>
                        <td>
                            {{ item.status }}
                        </td>
                        <td>
                            {{ ( item.ccb && item.ccb.numero_ccb ) ? item.ccb.numero_ccb : "" }}
                        </td>
                        <td :title="item.ccb.importacao.error_message">
                          {{ item.ccb && item.ccb.importacao && item.ccb.importacao.status }}
                          <i v-if="item.ccb && item.ccb.importacao && item.ccb.importacao.status && item.ccb.importacao.status == 'erro'" class="tim-icons icon-alert-circle-exc" style="color: #FF0000; font-weight: bold;"></i>
                          <i v-if="item.ccb && item.ccb.importacao && item.ccb.importacao.status && item.ccb.importacao.status == 'cancelado'" class="tim-icons icon-check-2" style="color: #008000; font-weight: bold;"></i>
                          <i v-if="item.ccb && item.ccb.importacao && item.ccb.importacao.status && item.ccb.importacao.status == 'concluído'" class="tim-icons icon-check-2" style="color: #008000; font-weight: bold;"></i>
                        </td>
                        <td style="text-align: center;">
                            <i v-if="item.ccb && item.ccb.importacao && item.ccb.importacao.status && item.ccb.importacao.error_message != 'Sem Contrato' && item.ccb.importacao.error_message != 'Contrato Duplicado'" title="Download Contrato CCB" class="tim-icons icon-cloud-download-93" style="cursor: pointer;" @click="downloadContratoCCB(item)"></i>
                        </td>
                    </tr>
                    <tr v-if="displayedData.length == 0">
                        <td colspan="10" style="text-align: center; font-style: italic;">
                            Não foram encontrados registros.
                        </td>
                    </tr>
                </tbody>
              </table>

              <!-- <nav class="table-pagination">
                <ul class="pagination">
                  <li class="page-item">
                    <button
                      type="button"
                      class="page-link"
                      v-if="page != 1"
                      @click="page--"
                    >
                      <i class="fas fa-angle-left"></i>
                    </button>
                  </li>

                  <li class="page-item">
                    <button
                      type="button"
                      class="page-link"
                      v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
                      :key="index"
                      @click="page = pageNumber"
                      :class="{ 'is-active': page === pageNumber }"
                    >
                      {{ pageNumber }}
                    </button>
                  </li>

                  <li class="page-item">
                    <button
                      type="button"
                      class="page-link"
                      v-if="page < pages.length"
                      @click="page++"
                    >
                      <i class="fas fa-angle-right"></i>
                    </button>
                  </li>
                </ul>
              </nav> -->
            </div>
          </div>

          <div class="row" v-if="!isLoading">
            <div class="col-12">
              <button class="btn" @click="confirmarOperacaoInvestimento()">
                CONFIRMAR OPERAÇÃO DE INVESTIMENTO
              </button>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
