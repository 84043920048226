<script>
export default {
  name: 'report-layout',
  props: {
    fiscal_year: Number,
    logo: String,
    report_data: Array,
    investidor: Object,
    platformName: String
  },
  methods: {
    formattedValue(value) {
      return (
        'R$ ' +
        parseFloat(value || '0')
          .toFixed(2)
          .toString()
          .replace('.', ',')
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      )
    }
  },
}
</script>

<template>
  <div style="margin: 72px; font-family: Arial, Helvetica, sans-serif;">
    <div style="display: flex; align-items: center;">
      <div style="display: flex; align-items: center; border: 1px solid #000; padding: 4px 8px; width: 100%;">
        <div style="width: 70%">
          <span style="flex: 1; font-weight: bold;">INFORME DE RENDIMENTOS FINANCEIROS<br /> ANO-CALENDÁRIO DE {{
            fiscal_year }} <br />IMPOSTO DE RENDA</span>
        </div>
        <div style="border-left: 1px solid #000; margin-left: 8px; width: 30%;">
          <!-- <img :src="logo" alt="Sua Imagem" style="max-width: 100%; height: auto;"> -->
          <div style="width: 100%; text-align: center;">
            <span style="font-weight: bold; font-size: 22px;">
              {{ platformName }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 20px;">
      <span style="text-decoration: underline; font-weight: bold; font-size: 14px;">Beneficiário dos Rendimentos:</span>

      <div style="margin-top: 10px; width: 100%; text-align: center;">
        <table style="border-collapse: collapse; width: 100%;">
          <thead>
            <tr style="border: 1px solid #000;">
              <th style="border: 1px solid #000; width: 70%;">Nome</th>
              <th style="border: 1px solid #000; width: 30%;">CPF</th>
            </tr>
          </thead>
          <tbody>
            <tr style="border: 1px solid #000;">
              <td style="border: 1px solid #000;">{{ investidor.nome }}</td>
              <td style="border: 1px solid #000;">{{ investidor.documento.numero }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div style="margin-top: 20px;">
      <span style="font-weight: bold; font-size: 12px;">
        04-02- Títulos públicos e privados sujeitos à tributação (Tesouro Direto, CDB, RDB e Outros)
      </span>
      <div style="margin-top: 10px; width: 100%; text-align: center; font-size: 10px;">
        <table style="border-collapse: collapse; width: 100%;">
          <thead>
            <tr style="border: 1px solid #000;">
              <th style="border: 1px solid #000; width: 30%;">Descrição</th>
              <th style="border: 1px solid #000; width: 14%;">Saldo em 2022</th>
              <th style="border: 1px solid #000; width: 14%;">Saldo em 2023</th>
              <th style="border: 1px solid #000; width: 14%;">Rendimento bruto</th>
              <th style="border: 1px solid #000; width: 14%;">IRRF</th>
              <th style="border: 1px solid #000; width: 14%;">Rendimento líquido</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(report, index) in report_data" :key="index" style="border: 1px solid #000;">
              <td style="border: 1px solid #000;">
                <div style="margin-bottom: 10px;">
                  <span>Nome da empresa: {{ report.nome }}</span>
                </div>
                <div>
                  <span>CNPJ: {{ report.cnpj }}</span>
                </div>
              </td>
              <td style="border: 1px solid #000;">R$ 0,00</td>
              <td style="border: 1px solid #000;">{{ formattedValue(report.valorBruto) }}</td>
              <td style="border: 1px solid #000;">R$ 0,00</td>
              <td style="border: 1px solid #000;">R$ 0,00</td>
              <td style="border: 1px solid #000;">R$ 0,00</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div style="font-size: 12px; page-break-before: always; margin-top: 72px;">
      <div style="margin-top: 20px; margin-bottom: 20px;">
        <span style="font-weight: bold; text-decoration: underline;">Instruções para preenchimento</span>
      </div>

      <span style="font-weight: bold;">Bens e Direitos: </span>
      <p>Informar o valor do investimento realizado (valor do saldo) no ano de transmissão do valor.</p>
      <span style="font-weight: bold;">Código para investimentos realizados: </span>
      <p>04-02: Aplicação de renda fixa (CDB, RDB e outros):</p>
      <p>Valor do crédito (Saldo), prazo e condições estipuladas (conforme contrato de investimento), nome
        e número de inscrição no CPF ou no CNPJ do devedor (conforme informado na página anterior).
      </p>
      <span style="font-weight: bold;">Rendimentos:</span>
      <p>Para a pessoa física os rendimentos equiparam-se a operações de renda fixa, para fins de
        incidência do imposto de renda na fonte. Tal tributação é considerada como definitiva, desta forma
        os rendimentos auferidos não integram a base de cálculo do imposto de renda na Declaração de
        Ajuste Anual, porém devem ser informados na ficha “Rendimentos Sujeitos à Tributação
        Exclusiva/Definitiva” Deve ser informado no momento/ano-calendário do recebimento dos juros
        (Rendimentos).</p>
      <p>O valor a ser informado deve ser o líquido, ou seja, descontar o IR do valor do rendimento. </p>
      <span style="font-weight: bold;">Observação sobre pagamentos realizados diretamente pela incorporadora: </span>
      <p>Em caso de eventual pagamento e/ou acordo realizado diretamente entre incorporador / tomador e
        investidor, sem intermediação da plataforma, os dados referentes a esse pagamento/acordo não
        estão contemplados neste relatório. Ou seja, a declaração dos valores referentes ao principal pago
        (saldo final em 2023), ganho de capital e retenção ficam sob responsabilidade do investidor. </p>
    </div>
  </div>
</template>
