import Axios from 'axios';

/**
 * financingId: financing id to get all transactions
 * accountKind: kind of transactions, enum value 'agent' | 'beneficiary' | 'marketplace' | 'platform'
 */
export default async (financingId, accountKind) => {
  const endpoint = process.env.VUE_APP_ENDPOINT_WS + 'getTransactions';
  try {
    return await Axios.post(endpoint, { financingId, accountKind }).then(r => {
      return r.data
    });
  } catch (error) {
    throw new Error(error.message);
  }
};
