// src/mixins/tipoPlataformaMixin.js
import { getModalidadePlataforma, TIPO_PLATAFORMA } from '@/helpers/tipoPlataformaHelper';

export const tipoPlataformaMixin = {
  data() {
    return {
      plataformaType: TIPO_PLATAFORMA,
    };
  },
  computed: {
    tipoPlataforma() {
      return getModalidadePlataforma();
    },
    isModalidadeEquity() {
      return (this.tipoPlataforma === this.plataformaType.P1) || (this.tipoPlataforma === this.plataformaType.P3);
    },
    isP1() {
      return this.tipoPlataforma === this.plataformaType.P1;
    },
    isDoacao() {
      return this.tipoPlataforma === this.plataformaType.DOACAO;
    },
    isP3() {
      return this.tipoPlataforma === this.plataformaType.P3;
    }
  }
};