<script>
import ErrorRegisterNotification from '@/components/Notifications/General/ErrorRegisterNotification';
import authenticationService from '@/services/Authentication/authenticationService';
import { vueTopprogress } from 'vue-top-progress';
import investorsService from '@/services/Investors/investorsService';
import pessoasService from '@/services/Pessoas/pessoasService';
import attachmentsService from '@/services/Attachments/attachmentsService';
import logsService from '@/services/Logs/logsService';

import claimsService from '@/services/Authentication/claimsService';
import Password from 'vue-password-strength-meter';

import Vue from 'vue';
import {
  PessoaCaracteristica,
  CWLFirebaseError,
  CWLRedirectRef,
  CWLStatusInvestidor
} from '@/store/cwl_enums';
import HelperPlugin from '@/plugins/HelperPlugin';
Vue.use(HelperPlugin);

import settingsService from '@/services/Settings/settingsService';

import birthDatepicker from 'vue-birth-datepicker';
import Modal from '@/components/Modal';

import pdfvuer from 'pdfvuer';
import { DoubleBounce } from 'vue-loading-spinner';

import { phone, cpf } from '@/validate/customValidators.js';

import Firebase from 'firebase/app';
import { email } from 'vuelidate/lib/validators';
const GoogleProvider = new Firebase.auth.GoogleAuthProvider();
const FacebookProvider = new Firebase.auth.FacebookAuthProvider();

export default {
  components: {
    vueTopprogress,
    Password,
    birthDatepicker,
    Modal,
    DoubleBounce,
    pdf: pdfvuer,
  },
  data() {
    return {
      termsOfUsageContent: '',
      modalidade: process.env.VUE_APP_TYPE,
      submodalidade: process.env.VUE_APP_SUBTYPE,
      facebookDisabled: process.env.VUE_APP_FACEBOOK_LOGIN_ENABLED === 'false',
      pessoa: {
        telefone: {},
        documento: {},
        email: {},
      },
      password: '',
      birthday: '',
      envAppRootPortal: process.env.VUE_APP_ROOT_PORTAL,
      logoUrl: require(process.env.VUE_APP_LOGO_URL),
      acceptTerm: '',
      showModal: false,
      comDadosDasMidiasSociais: false,
      userFromFirebase: null,
      claim: null,

      page: 1,
      numPages: 0,
      pdfdata: undefined,
      scale: 'page-width',
      lastAttachment: {},
      isLoading: false,

      allSettings: {},
      socialLoginButtonDisabled: false,
      passwordButtonVisible: true,
      titulo: ''
    };
  },
  validations: {
    pessoa: {
      telefone: {
        valid: ({ telefone }) => phone(telefone),
      },
      email: {
        email: {
          valid: email,
        },
      },
      documento: {
        numero: {
          valid: cpf,
        },
      },
    },
  },
  computed: {
    appTitle() {
      return process.env.VUE_APP_TITLE;
    },
    loggedInUser() {
      return this.$store.getters.isLoggedIn;
    },
    userTemp() {
      return this.$store.getters.getUserTemp;
    },
    ref() {
      return this.$route.params.ref ? this.$route.params.ref : '';
    },
  },
  mounted() {
    this.pessoa = this.$CWLNewPessoaPadrao();
    this.checkUserLogged();
    this.getSettings();
  },
  methods: {
    async getSettings() {
      this.allSettings = (await settingsService.getPublicSettings()) || {};
      let privateSettings = (await settingsService.getPrivateSettings()) || {};

      this.termsOfUsageContent =
        this.allSettings.terms.condicoes_termos_uso || '';

      console.log()
      this.titulo = privateSettings.platformName || '';
    },
    finangingSiteUrl(id) {
      let url = process.env.VUE_APP_FINANCING_PAGE || process.env.VUE_APP_ROOT_SITE + '/campanhas/detalhes?id={id}&checkout=t';
      url = url.replace('{id}', id);
      return url;
    },
    redirectTo() {
      try {
        const ref = this.$route.params.ref || this.$route.query.ref;
        const id =
          this.$route.query.id || this.$store.getters.paymentCheckout.financingId;

        if (ref === 'site' || id) {
          const siteUrl = this.finangingSiteUrl(id);
          window.top.location.href = siteUrl;
        } else if (ref == CWLRedirectRef.siteCriarCampanha) {
          this.$router.push('/add-financing');
        } else if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect);
        } else {
          if ( this.pessoa.dados_complementares.approvalStatus != CWLStatusInvestidor.Aprovado
          && this.pessoa.dados_complementares.approvalStatus != CWLStatusInvestidor.EmAnalise ) {
            this.$router.push('/profile');
          }
          else {
            this.$router.push('/dashboard');
          }
        }
      } catch (error) {
        console.error(error);
      }
      
    },
    checkUserLogged() {
      if (Object.keys(this.loggedInUser).length > 0) {
        this.redirectTo();
      }

      if (Object.keys(this.userTemp).length > 0) {
        this.getRedirectResult(this.userTemp, this.userTemp.user);
      }
    },
    async updatePessoa(pessoaToUpdate) {
      try {
        const { uid, email } = await this.userFromFirebase.user;

        pessoaToUpdate.caracteristicas = [PessoaCaracteristica.Usuario];
        pessoaToUpdate.dados_complementares = {
          birthday: this.birthday,
          user: {
            maritalStatus: '',
            email: email,
          },
          address: {
            state: '',
          },
          bank: {
            bankName: '',
          },
          profile: {
            personType: '',
            segment: '',
            value: '',
            profile: '',
          },
          fullName: '',
          approvalStatus: CWLStatusInvestidor.Incompleto,
          termsOfUseAccepted: false,
          termsOfUseDateAccepted: '',
        };

        pessoaToUpdate.nome = this.pessoa.nome;

        pessoaToUpdate.email.email = email;
        pessoaToUpdate.emails = [];
        pessoaToUpdate.emails.push(pessoaToUpdate.email);

        pessoaToUpdate.telefone = this.pessoa.telefone;
        pessoaToUpdate.telefones = [];
        pessoaToUpdate.telefones.push(this.pessoa.telefone);

        pessoaToUpdate.documento = this.pessoa.documento;
        pessoaToUpdate.documentos = [];
        pessoaToUpdate.documentos.push(this.pessoa.documento);

        pessoaToUpdate.dados_complementares.termsOfUseAccepted = true;

        const pessoaAtualizada = await pessoasService.updatePessoa(
          pessoaToUpdate.id,
          pessoaToUpdate,
        );
        const pessoaAtualizadaFromFirebase = await investorsService.get(
          pessoaToUpdate.id,
        );
        const pessoaAtualizadaData = pessoaAtualizadaFromFirebase.data();
        pessoaAtualizadaData.id = pessoaAtualizadaFromFirebase.id;

        const objCooke = this.$CWLCreatePessoaForCookie(pessoaAtualizadaData);

        this.$store.commit('SET_PESSOA', objCooke);

        await claimsService.setNewUserClaim(uid, email, 'investor');

        this.$store.commit('SET_USER', this.userFromFirebase.user);

        await claimsService.getUserClaim(this.userFromFirebase.user);

        logsService.createNewLog(
          this.userFromFirebase,
          'Registrar',
          'SignUp',
          'POST',
          pessoaToUpdate,
        );

        this.redirectTo();
        this.$refs.topProgress.done();

        const title = 'Parabéns ' + this.pessoa.nome + '!';
        this.$notify({
          title: title,
          message: 'Agora você faz parte da nossa plataforma.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (e) {
        console.error(e);
        this.socialLoginButtonDisabled = false;
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
        this.$refs.topProgress.done();
      }
    },
    async createPessoa() {
      try {
        if (this.claim == null) {
          this.claim = await claimsService.getUserClaim(
            this.userFromFirebase.user,
          );
        }
        if (this.claim != null) {
          // console.log(this.userFromFirebase);
          // this.$store.dispatch('login', this.userFromFirebase.user);
          this.$store.commit('SET_USER', this.userFromFirebase.user);
          const objCooke = this.$CWLCreatePessoaForCookie(this.pessoa);
          // console.log(objCooke);
          this.$store.commit('SET_PESSOA', objCooke);

          logsService.createNewLog(
            this.userFromFirebase,
            'Registrar - Já Registrado',
            'SignUp',
            'POST',
            this.pessoa,
          );

          this.$refs.topProgress.done();
          this.redirectTo();

          const title = 'Olá ' + this.pessoa.nome + '!';
          this.$notify({
            title: title,
            message:
              'Identificamos que você já fazia parte da nossa plataforma. Bem vindo de volta!',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 3000,
          });
        } else {
          const { uid, email } = await this.userFromFirebase.user;

          if (this.pessoa.email.email) {
            this.pessoa.email.email = this.pessoa.email.email.toLowerCase();
          }

          this.pessoa.caracteristicas = [PessoaCaracteristica.Usuario];
          this.pessoa.dados_complementares = {
            birthday: this.birthday,
            user: {
              maritalStatus: '',
              email: this.pessoa.email.email,
            },
            address: {
              state: '',
            },
            bank: {
              bankName: '',
            },
            profile: {
              personType: '',
              segment: '',
              value: '',
              profile: '',
            },
            fullName: '',
            approvalStatus: CWLStatusInvestidor.Incompleto,
            termsOfUseAccepted: false,
            termsOfUseDateAccepted: '',
          };

          this.pessoa.emails.push(this.pessoa.email);
          this.pessoa.telefones.push(this.pessoa.telefone);
          this.pessoa.documentos.push(this.pessoa.documento);
          this.pessoa.dados_complementares.termsOfUseAccepted = true;

          const pessoaCriada = await investorsService.createNewInvestor(
            this.pessoa,
          );
          const pessoaFromFirebase = await investorsService.get(
            pessoaCriada.id,
          );
          const pessoaCriadaData = pessoaFromFirebase.data();
          pessoaCriadaData.id = pessoaFromFirebase.id;
          const objCooke = this.$CWLCreatePessoaForCookie(pessoaCriadaData);
          this.$store.commit('SET_PESSOA', objCooke);

          await claimsService.setNewUserClaim(uid, email, 'investor');

          this.$store.commit('SET_USER', this.userFromFirebase.user);

          await claimsService.getUserClaim(this.userFromFirebase.user);

          this.$store.commit('SET_CLAIM', {uid: uid, email: email, role: 'investor'});

          logsService.createNewLog(
            this.userFromFirebase,
            'Registrar',
            'SignUp',
            'POST',
            this.pessoa,
          );

          this.redirectTo();
          this.$refs.topProgress.done();

          const title = 'Parabéns ' + this.pessoa.nome + '!';
          this.$notify({
            title: title,
            message: 'Agora você faz parte da nossa plataforma. Complete seu cadastro acessando Perfil > Meus Dados.',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 3000,
          });
        }
      } catch (e) {
        console.error(e);
        this.socialLoginButtonDisabled = false;
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
        this.$refs.topProgress.done();
      }
    },
    async createUser() {
      if (this.$v.$invalid) {
        return this.$notify({
          title: 'Informe os dados corretamente!',
          message:
            'Um ou mais dados informados não foram preenchidos corretamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 7000,
        });
      }

      this.$refs.topProgress.start();

      try {
        const pessoasFound = await pessoasService.searchByPrincipalDocument(
          this.pessoa.documento.numero,
        );
        if (pessoasFound.size == 0) {
          if (!this.userFromFirebase || !this.userFromFirebase.user) {
            this.userFromFirebase = await authenticationService.createNewUser(
              this.pessoa.email.email,
              this.password,
            );
          }
          this.createPessoa();
        } else {
          const newPessoaFromFirebase = pessoasFound.docs[0];
          var newPessoaData = newPessoaFromFirebase.data();
          newPessoaData.id = newPessoaFromFirebase.id;
          const claims = await claimsService.getUserClaimByEmail(
            newPessoaData.email.email,
          );
          if (claims.size == 0) {
            if (!this.userFromFirebase || !this.userFromFirebase.user) {
              this.userFromFirebase = await authenticationService.createNewUser(
                this.pessoa.email.email,
                this.password,
              );
              this.updatePessoa(newPessoaData);
            } else {
              this.socialLoginButtonDisabled = false;
              console.error('erro ao criar usuario e atualizar dados de usuario');
              this.$notify({
                title: 'Ooopss',
                message: 'Desculpe, ocorreu um erro. Tente novamente.',
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'warning',
                timeout: 3000,
              });
            }
          } else {
            var title = "E-mail já existente!";
            var message = "Por favor, faça contato conosco ou cadastre outro e-mail.";
            if ( newPessoaData.documento.numero == this.pessoa.documento.numero ) {
              title = "CPF já existente";
              message = "Por favor, faça contato conosco ou cadastre outro telefone.";
            }
            this.socialLoginButtonDisabled = false;
            this.$notify({
              title: title,
              message: message,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'warning',
              timeout: 3000,
            });
            this.$refs.topProgress.done();
          }
        }
      } catch (e) {
        console.error(e);
        this.socialLoginButtonDisabled = false;
        if ((e.code = CWLFirebaseError.emailJaExistente)) {
          this.$notify({
            title: 'E-mail já existente!',
            message:
              'Por favor, faça contato conosco ou cadastre outro e-mail.',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 3000,
          });
        } else {
          this.socialLoginButtonDisabled = false;
          this.$notify({
            title: 'Ooopss',
            message: 'Desculpe, ocorreu um erro. Tente novamente.',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 3000,
          });
        }
        this.password = '';
        this.$refs.topProgress.done();
      }
    },
    navigateToSite() {
      this.$router.push('/login');
    },
    async enterWithGoogle() {
      const that = this;
      this.$refs.topProgress.start();
      that.socialLoginButtonDisabled = true;

      Firebase.auth()
        .signInWithPopup(GoogleProvider)
        .then(function (result) {
          var result = result;
          var user = result.user;
          that.getRedirectResult(result, user);
        })
        .catch(function (error) {
          console.log(error);
          that.socialLoginButtonDisabled = false;
          var comp = ErrorRegisterNotification;
          if (error.code == 'auth/account-exists-with-different-credential') {
            comp = EmailDeOutroProvedor;
          }

          that.$refs.topProgress.done();
          if (error.code != 'auth/popup-closed-by-user') {
            that.$notify({
              component: comp,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'warning',
              timeout: 3000,
            });
          }
        });
    },
    async enterWithFacebook() {
      const that = this;
      this.$refs.topProgress.start();
      that.socialLoginButtonDisabled = true;

      Firebase.auth()
        .signInWithPopup(FacebookProvider)
        .then(function (result) {
          var result = result;
          var user = result.user;
          that.getRedirectResult(result, user);
        })
        .catch(function (error) {
          that.socialLoginButtonDisabled = false;
          var comp = ErrorRegisterNotification;
          if (error.code == 'auth/account-exists-with-different-credential') {
            comp = EmailDeOutroProvedor;
          }

          that.$refs.topProgress.done();
          if (error.code != 'auth/popup-closed-by-user') {
            that.$notify({
              component: comp,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'warning',
              timeout: 3000,
            });
          }
        });
    },
    async getRedirectResult(resultFromFirebase, userFromFirebase) {
      const that = this;
      that.socialLoginButtonDisabled = true;
      that.passwordButtonVisible = false;

      try {
        this.userFromFirebase = resultFromFirebase;
        if (resultFromFirebase && resultFromFirebase.user) {
          this.claim = await claimsService.getUserClaim(
            resultFromFirebase.user,
          );

          if (this.claim != null) {
            investorsService
              .getInvestor(resultFromFirebase.user.email)
              .then((response) => {
                response.forEach((doc) => {
                  const pessoaTemp = doc.data();
                  pessoaTemp.id = doc.id;
                  that.pessoa = pessoaTemp;
                  that.createPessoa();
                });
              });
          } else {
            this.pessoa.nome = resultFromFirebase.user.displayName;
            this.pessoa.email.email = resultFromFirebase.user.email;
            this.pessoa.photoURL = resultFromFirebase.user.photoURL;
            this.comDadosDasMidiasSociais = true;
            this.$refs.topProgress.done();
          }
        } else {
          this.$refs.topProgress.done();
        }
      } catch (error) {
        console.error(error);
        that.socialLoginButtonDisabled = false;
        var message = 'Desculpe, ocorreu um erro. Tente novamente.';
        if (error.code == 'auth/account-exists-with-different-credential') {
          message =
            'Outra conta foi utilizada com este mesmo e-mail. Por favor, utilize a conta correta.';
        }
        this.$notify({
          title: 'Ooopss',
          message: message,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
        this.$refs.topProgress.done();
      }
    },
    checkAcceptTerms() {
      this.$refs.accept_term.click();
    },

    openUseTerms() {
      this.showModal = true;
    },

    getPdf(url) {
      const self = this;
      self.pdfdata = pdfvuer.createLoadingTask(url);

      self.pdfdata.then((pdf) => {
        self.numPages = pdf.numPages;

        window.onscroll = function () {
          changePage();
        };

        // eslint-disable-next-line require-jsdoc
        function changePage() {
          let i = 1;
          const count = Number(pdf.numPages);

          do {
            if (
              window.pageYOffset >= self.findPos(document.getElementById(i)) &&
              window.pageYOffset <= self.findPos(document.getElementById(i + 1))
            ) {
              self.page = i;
            }
            i++;
          } while (i < count);
          if (window.pageYOffset >= self.findPos(document.getElementById(i))) {
            self.page = i;
          }
        }
      });
    },

    findPos(obj) {
      return 0;
    },

    async getAttachments() {
      this.isLoading = true;

      try {
        const attachments = await attachmentsService.getAttachments(
          'TermsOfUse',
        );
        const attachmentsItems = await attachments.items;
        if (attachmentsItems.length == 0) {
          this.$notify({
            title: 'Ooopss',
            message: 'Desculpe, ocorreu um erro. Tente novamente.',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 3000,
          });
        } else {
          this.lastAttachment = attachmentsItems[0];
          const url = await attachmentsService.urlAttachment(
            this.lastAttachment.name,
            'TermsOfUse',
          );
          this.getPdf(url);
        }
      } catch (e) {
        console.error(e);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<template>
  <div class="sign-in">
    <div class="signin2"></div>
    <div class="card">
      <div class="back" style="margin-top: -30px !important">
        <i class="fa fa-arrow-left" style="color: #222223;" @click="navigateToSite()"></i>
      </div>

      <h1 id="logo">Agrocorp</h1>

      <p v-if="!comDadosDasMidiasSociais">
        Para se registrar, informe os dados abaixo:
      </p>
      <p v-if="comDadosDasMidiasSociais">
        Por favor, complete os dados abaixo:
      </p>
      <p style="font-size: 11px; font-style: italic">
        * Todos os campos são obrigatórios
      </p>

      <div class="social-network-buttons">
        <button
          @click="enterWithGoogle"
          class="btn btn-warning"
          :disabled="socialLoginButtonDisabled"
        >
          Entrar com Google
        </button>

        <!-- <button
          v-if="!facebookDisabled"
          @click="enterWithFacebook"
          class="btn btn-info"
          :disabled="socialLoginButtonDisabled"
        >
          Entrar com Facebook
        </button> -->
      </div>

      <form @submit.prevent="createUser" class="login-form">
        <div class="form-group">
          <label for="" class="control-label">Nome Completo*</label>
          <input
            v-model="pessoa.nome"
            type="text"
            class="Password__field"
            placeholder="Digite seu nome completo"
            required
          />
        </div>

        <div class="form-group">
          <label for="" class="control-label">Data de Nascimento*</label><br />
          <birth-datepicker
            v-model="birthday"
            v-mask="'##/##/####'"
            :valueIsString="true"
            :high="true"
            :hideHeader="true"
            :attachment="'bottom'"
            :class="'Password__field'"
            :delimiter="'/'"
            :placeholder="'Digite seu nascimento. Ex: 27/12/1993'"
            :locale="[
              'Jan',
              'Fev',
              'Mar',
              'Abr',
              'Mai',
              'Jun',
              'Jul',
              'Ago',
              'Set',
              'Out',
              'Nov',
              'Dez',
            ]"
          />
        </div>

        <div class="form-group">
          <base-input
            label="Telefone*"
            v-model="pessoa.telefone.telefone"
            placeholder="Digite seu melhor telefone"
            v-mask="['(##) ####-####', '(##) #####-####']"
            :bigInput="true"
            :bigLabel="true"
            required
          ></base-input>
          <div
            class="error"
            v-if="pessoa.telefone.telefone != '' && !$v.pessoa.telefone.valid"
          >
            Informe um telefone com DDD válido.
          </div>
        </div>

        <div class="form-group">
          <base-input
            label="CPF*"
            v-model="pessoa.documento.numero"
            placeholder="Digite seu CPF"
            v-mask="['###.###.###-##']"
            :bigInput="true"
            :bigLabel="true"
            required
          ></base-input>
          <div
            class="error"
            v-if="
              pessoa.documento.numero != '' && !$v.pessoa.documento.numero.valid
            "
          >
            Informe um CPF válido.
          </div>
        </div>

        <div class="form-group">
          <label for="" class="control-label">Email*</label>
          <input
            v-model="pessoa.email.email"
            data-testId="email"
            type="email"
            class="Password__field"
            placeholder="Digite seu melhor e-mail"
            required
          />
          <div
            class="error"
            v-if="pessoa.email.email != '' && !$v.pessoa.email.email.valid"
          >
            Informe um e-mail válido.
          </div>
        </div>

        <div class="form-group" v-if="passwordButtonVisible">
          <label for="" class="control-label"
            >Senha (mínimo 6 caracteres)*</label
          >
          <password
            v-model="password"
            :secureLength="6"
            :toggle="true"
            required
          />
        </div>

        <div class="form-group">
          <input
            name="accept_term"
            ref="accept_term"
            type="checkbox"
            required
            v-model="acceptTerm"
          />
          <label
            for="accept_term"
            style="margin-left: 5px; color: #222223;"
            @click="checkAcceptTerms"
          >
            Li e concordo com as </label>
          <a href="#" @click="openUseTerms"> Condições e Termos de Uso</a>
        </div>

        <button type="submit" class="btn btn-primary" style="background-color: #4BAD5D !important; color: #fff !important;" v-if="acceptTerm">
          Registrar
        </button>
        <button class="btn btn-primary" style="background-color: #4BAD5D !important; color: #fff !important;" v-if="!acceptTerm" disabled>
          Registrar
        </button>

        <div class="form-group">
          <label for="" style="color: #222223;">
            Já tem uma conta?
            <a :href="`/login/${ref}`" class="create-account">Entrar</a>
          </label>
        </div>
      </form>

      <modal :show="showModal" @close="showModal = false">
        <div class="term-of-use">
          Estas são nossas <b>Condições</b> e <b>Termos de Uso</b> da
          plataforma. Leia com atenção e qualquer dúvida, entre em contato.
          <button
            style="
              background-color: rgb(255, 255, 255);
              border: 1px solid #aaa;
              border-radius: 20px;
              height: 40px;
              width: 40px;
              position: absolute;
              right: 0;
              top: 10px;
              cursor: pointer;
              color: #222223;
            "
            @click="showModal = false"
          >
            <i class="tim-icons icon-simple-remove"></i>
          </button>
          <br />
          <br />
          <div
            class="card"
            style="
              background-color: #fff !important;
              max-width: 1024px !important;
            "
          >
            <div class="login-form" style="max-width: 1024px">
              <div v-html="termsOfUsageContent"></div>
            </div>
          </div>
        </div>
      </modal>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>

<style scoped>

.sign-in {
    background-color: #fff!important;
    background-image: url(https://firebasestorage.googleapis.com/v0/b/ecwl-agrocorp-stage.appspot.com/o/auth-background.png?alt=media&token=41342f78-8395-410c-a418-f0ed66ed0f35);
    background-repeat: no-repeat;
    background-size: 100%;
}

.signin2 {
  background-color: #000!important;
  opacity: 0;
  width: 100%;
  position: absolute;
  height: 140vh;
}


.sign-in form>button.btn {
    background: #0006B4!important;
    color: #E4E6ED!important;
}


.sign-in form>button.btn:hover {
    background: #00035A!important;
    
}

.sign-in > .card {
  background-color: #f5f6fa;
  border-radius: 0.5rem;
  /* border-color: #f5f6fa !important; */
  /* border-width: 1px !important; */
  /* border: outset; */
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, .15);
}

.sign-in h1#logo {
    overflow: hidden;
    background-repeat: no-repeat;
    height: 40px !important;
    background-position: center;
    font-size: 28px;
    color: #222223;
    font-family: Poppins, Arial, Helvetica, sans-serif !important;
    font-weight: bold;
}

.form-control {
    border-color: #222223 !important;
}

.control-label {
  color: #222223;
}

div .error {
  color: #f00;
}

.form-group > a {
  color: #00f !important;
}

.Password__field {
  padding: 13px !important;
  border: 1px solid #f1f1f1 !important;
  border-radius: 2px !important;
  height: 48px !important;
}

p {
  color: #222223 !important;
}

</style>