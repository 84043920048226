<script>
import ConfirmationModal from '@/components/ConfirmationModal';

import XLSX from 'xlsx';

import Vue from 'vue';

import HelperPlugin from '@/plugins/HelperPlugin';
Vue.use(HelperPlugin);

export default {
  name: 'users-table',
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: 'Table columns',
    },
    data: {
      type: Array,
      default: () => [],
      description: 'Table data',
    },
    type: {
      type: String,
      default: '',
      description: 'Whether table is striped or hover type',
    },
    theadClasses: {
      type: String,
      default: '',
      description: '<thead> css classes',
    },
    tbodyClasses: {
      type: String,
      default: '',
      description: '<tbody> css classes',
    },
    hasActions: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDownloadable: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      page: 1,
      perPage: 10,
      pages: [],
      showModal: false,
      preDeletedItem: {},

      searchBar: '',
      // Mantenha uma cópia dos dados originais
      originalData: [],
      items_exportar: []
    };
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
    displayedData() {
      return this.paginate(this.filteredData);
    },
    filteredData() {
      this.items_exportar = [];
      
      return this.originalData.filter(obj => {
        this.items_exportar.push({
          id: obj.id,
          nome: obj.nome || '',
          email: obj.email ? obj.email.email : '',
          telefone: obj.telefone ? obj.telefone.telefone : '',
          data_nascimento: obj.dados_complementares.birthday || '',
          cpf: obj.documento ? obj.documento.numero : '',
          // Dados Complementares
          rg: obj.dados_complementares.rg || '',
          rg_orgao_expeditor: obj.dados_complementares.rg_orgao_expeditor || '',
          rg_uf_orgao_expeditor: obj.dados_complementares.rg_uf_orgao_expeditor || '',
          rg_data_emissao: obj.dados_complementares.rg_data_emissao || '',
          nome_mae: obj.dados_complementares.nome_mae || '',
          naturalidade: obj.dados_complementares.naturalidade || '',
          estado_civil: obj.dados_complementares.estado_civil ? obj.dados_complementares.estado_civil.descricao : '',
          genero: obj.dados_complementares.genero ? obj.dados_complementares.genero.descricao : '',
          // Endereço
          endereco_cep: (obj.endereco && obj.endereco.cep) ? obj.endereco.cep : '',
          endereco_numero: (obj.endereco && obj.endereco.numero) ? obj.endereco.numero : '',
          endereco_complemento: (obj.endereco && obj.endereco.complemento) ? obj.endereco.complemento : '',
          endereco_bairro: (obj.endereco && obj.endereco.bairro) ? obj.endereco.bairro : '',
          endereco_rua: (obj.endereco && obj.endereco.rua) ? obj.endereco.rua : '',
          endereco_cidade: (obj.endereco && obj.endereco.cidade) ? obj.endereco.cidade : '',
          endereco_estado: (obj.endereco && obj.endereco.estado) ? obj.endereco.estado.uf : '',
          // Profissão
          ocupacao: obj.dados_complementares.ocupacao ? obj.dados_complementares.ocupacao : '',
          nome_empresa: obj.dados_complementares.nome_empresa ? obj.dados_complementares.nome_empresa : '',
          cargo: obj.dados_complementares.cargo ? obj.dados_complementares.cargo : '',
          // Dados bancários
          agencia_sem_digito: obj.dados_complementares.dados_bancarios ? obj.dados_complementares.dados_bancarios.agencia : '',
          conta_com_digito: obj.dados_complementares.dados_bancarios ? obj.dados_complementares.dados_bancarios.conta : '',
          banco: (obj.dados_complementares.dados_bancarios && obj.dados_complementares.dados_bancarios.banco) ? obj.dados_complementares.dados_bancarios.banco.code + " - " + obj.dados_complementares.dados_bancarios.banco.name : '',
          // Perfil de Investimento
          perfil_investimento: obj.dados_complementares.perfil ? obj.dados_complementares.perfil.perfil : '',
          perfil_segmento: obj.dados_complementares.perfil ? obj.dados_complementares.perfil.segmento : '',
          perfil_valor: obj.dados_complementares.perfil ? obj.dados_complementares.perfil.valor : '',
          aparecer_como_investidor: obj.dados_complementares.aparecer_investidor ? "Sim" : 'Não',
          politicamente_exposto: obj.dados_complementares.politicamente_exposto ? "Sim" : 'Não',
          renda: obj.dados_complementares.perfil ? obj.dados_complementares.perfil.renda_bruta_anual : '',
          investimentos_financeiros: obj.dados_complementares.perfil ? obj.dados_complementares.perfil.investimentos_financeiros : '',
          investimentos_outras_plataformas: obj.dados_complementares.perfil ? obj.dados_complementares.perfil.investimentos_outras_plataformas : '',
          investimentos_nesta_plataforma: obj.dados_complementares.perfil ? obj.dados_complementares.perfil.investimentos_nesta_plataforma : '',
        });

        return(
             (obj.nome ? obj.nome : '').toLowerCase().includes(this.searchBar.toLowerCase())
          || (obj.email ? obj.email.email : '').toLowerCase().includes(this.searchBar.toLowerCase())
          || (obj.telefone ? obj.telefone.telefone : '').toLowerCase().includes(this.searchBar.toLowerCase())
          || (obj.caracteristicas ? this.descricaoCaracteristicas(obj.caracteristicas) : '').toLowerCase().includes(this.searchBar.toLowerCase())
        )
      });
    },
  },
  mounted() {
    // Carrega os dados originais e armazena-os em originalData
    this.updateOriginalData(this.data);
    console.log('this.originalData: ', this.originalData);
  },
  methods: {
    // Método para atualizar os dados originais
    updateOriginalData(data) {
      this.originalData = data;
    },
    hasValue(item, column) {
      return item[column.toLowerCase()] !== 'undefined';
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    setPages() {
      if (this.pages) {
        this.pages = [];
      }

      const numberOfPages = Math.ceil(this.filteredData.length / this.perPage);

      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      const page = this.page;
      const perPage = this.perPage;

      const from = (page * perPage) - perPage;
      const to = (page * perPage);

      let dataslice = data.slice(from, to);

      this.page = 1;
      this.setPages();

      return dataslice;
    },
    preDeleteItem(item, index) {
      this.preDeletedItem = {
        item,
        index,
      };

      this.showModal = true;
    },
    deleteItem() {
      this.$emit('deleteItem', this.preDeletedItem);

      this.showModal = false;
    },

    descricaoCaracteristicas(caracteristicas) {
      return this.$CWLDescricaoCaracteristica(caracteristicas);
    },
    async downloadDados() {
      const data = XLSX.utils.json_to_sheet(this.items_exportar);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, 'data');
      XLSX.writeFile(wb, 'Usuarios.xlsx');
    },
  },
  watch: {
    data() {
      this.setPages();
    },
  },
};
</script>

<template>
  <div v-if="!isLoading">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Para pesquisar, digite sua busca aqui..." v-model="searchBar" />
        </div>
      </div>
    </div>
    <table class="table table-hover" :class="tableClass">
      <thead class="thead-light" :class="theadClasses">
        <tr>
          <slot name="columns">
            <th>Tipo de Usuário</th>
            <th>Nome</th>
            <th>E-mail</th>
            <th style="text-align: center;">Telefone</th>
            <th style="text-align: center;">Ações</th>
          </slot>
        </tr>
      </thead>
      <tbody :class="tbodyClasses">
        <tr v-for="(item, index) in displayedData" :key="index"  style="cursor: default !important; height: 60px;">
          <td>
            {{ descricaoCaracteristicas(item.caracteristicas) }}
          </td>
          <td>
            <router-link :to="`edit-user/${item.id}`" title="Ver usuário">
              {{ item.nome }}
            </router-link>
          </td>
          <td>{{ (item.email ? item.email.email : ' -- ') }}</td>
          <td style="text-align: center;">{{ (item.telefone ? item.telefone.telefone : ' -- ') }}</td>
          
          <td style="text-align: center;" v-if="hasActions" width="12%">
            <router-link :to="`edit-user/${item.id}`" title="Ver usuário" class="button-action">
              <i class="tim-icons icon-paper"></i>
            </router-link>
            <a @click="preDeleteItem(item, index)" href="#" style="color: red" title="Excluir usuário" class="button-action">
              <i class="tim-icons icon-trash-simple"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="row">
      <button
        type="button"
        class="btn btn-sm"
        style="padding: 10px"
        @click="downloadDados"
      >
        Exportar para Excel
      </button>
    </div>

    <nav class="table-pagination">
      <ul class="pagination">
        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-if="page != 1"
            @click="page--"
          >
            <i class="fas fa-angle-left"></i>
          </button>
        </li>

        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
            :key="index"
            @click="page = pageNumber"
            :class="{ 'is-active': page === pageNumber }"
          >
            {{ pageNumber }}
          </button>
        </li>

        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-if="page < pages.length"
            @click="page++"
          >
            <i class="fas fa-angle-right"></i>
          </button>
        </li>
      </ul>
    </nav>

    <confirmation-modal
      :show="showModal"
      @close="showModal = false"
      @confirmDelete="deleteItem()"
    ></confirmation-modal>
  </div>
</template>

<style>
  .button-action {
    margin-left: 10px;
    margin-right: 10px;
  }
</style>